import { Router } from "@reach/router";
import { parse } from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import Login from "./components/Login/Login";
import Phonecall from "./components/Phonecall/Phonecall";
import ProcessPoller from "./components/Process/ProcessPoller";
import ProductInformation from "./components/Product/Product";
import Proposal from "./components/Proposal/Proposal";
import Result from "./components/Result/Result";
import Root from "./components/Root";
import ErrorPage from "./components/Static/ErrorPage";
import NotFound from "./components/Static/NotFound";
import Unauthorized from "./components/Static/Unauthorized";
import OrderSummary from "./components/Summary/Summary";
import User from "./components/User/User";
import { GlobalStyle } from "./GlobalStyle";
import { setTheme } from "./redux/reducers/theme/actions";
import { getTheme } from "./redux/reducers/theme/selectors";
import { THEME } from "./utility/constants";

export default function Routes() {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  useEffect(() => {
    const { search, host } = window.location;
    const { t } = parse(search);
    if (host === "kaup.vodafone.is" || t === "vf") {
      dispatch(setTheme(THEME.VODAFONE));
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={{ theme: theme }}>
      <GlobalStyle />
      <Router>
        <NotFound path="/notfound" default />
        <Root path="/" />
        <ProductInformation path="/order" />
        <Login path="/login" />
        <Result path="/phonecall/result" type="phonecall" />
        <Unauthorized path="/unauthorized" />
        <ErrorPage path="/error" />
        <Phonecall path="/phonecall" />
        <User path="/user" />
        <OrderSummary path="/order/summary" />
        <Proposal path="/order/proposal" />
        <Result path="/order/result" />
        <ProcessPoller path="/order/process" />
      </Router>
    </ThemeProvider>
  );
}
