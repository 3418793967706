import { ApolloProvider } from "@apollo/react-hooks";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import client from "./apollo-client";
import Banner from "./components/Shared/Banner/Banner";
import Menu from "./components/Shared/Menu/Menu";
import configureStore from "./redux/configureStore";
import Routes from "./Routes";
import raygun from "./utility/raygun";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const tagManagerArgs = {
  gtmId: "GTM-PRJ8PTZ",
};

const store = configureStore();
raygun();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <Banner />
          <Menu />
          <Routes />
        </ReduxProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default App;
