const getDonations = (state) => state.donations.donations;
const getActiveDonation = (state) => state.donations.activeDonation;
const getActiveDonationType = (state) => state.donations.activeDonation?.type;
const getIsDonating = (state) => state.donations.isDonating;
const getDonationProduct = (state) => state.donations.donationProduct;

export {
  getDonations,
  getActiveDonation,
  getActiveDonationType,
  getIsDonating,
  getDonationProduct
};
