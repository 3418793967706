import { Link } from "@reach/router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMenu } from "../../../redux/reducers/menu/actions";
import { menuShown } from "../../../redux/reducers/menu/selectors";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import {
  getUserName,
  isAuthenticated,
} from "../../../redux/reducers/user/selectors";
import { Button } from "../Button/Button";
import { color } from "../Color/colors";
import {
  ButtonContainer,
  MenuContainer,
  MenuHeader,
  MenuSubContainer,
} from "./Styles";

export default function Menu() {
  const dispatch = useDispatch();
  const show = useSelector(menuShown);
  const user = useSelector(isAuthenticated);
  const name = useSelector(getUserName);
  const theme = useSelector(getTheme);

  function logout() {
    dispatch(showMenu(false));
  }

  return (
    show &&
    user && (
      <MenuContainer theme={theme}>
        <MenuSubContainer>
          <MenuHeader theme={theme}>{name}</MenuHeader>
          <ButtonContainer>
            <Link to="/" style={{ width: "100%" }}>
              <Button
                active
                color={color.white}
                marginRight={"0"}
                margin={"0"}
                marginTop={"0"}s
                marginBottom={"0"}
                maxWidth={"102px"}
                onClick={() => logout()}
              >
                ÚTSKRÁ
              </Button>{" "}
            </Link>
          </ButtonContainer>
        </MenuSubContainer>
      </MenuContainer>
    )
  );
}
