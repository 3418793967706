import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { isEmail } from "validator";
import { getProductName } from "../../redux/reducers/product/selectors";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import InputError from "../Shared/Input/InputError";
import { ActionContainer, Container } from "../Shared/Layout/Layout";
import ProductImage from "../Shared/ProductImage/ProductImage";
import {
  Button,
  LabelText,
  LargeHeader,
  NormalText,
  Spinner,
  SubHeader,
  Layout,
} from "../Shared/Shared";
import { ORDER_PHONECALL } from "./mutations";
import { Form, FormItem, PhoneCallInput as Input } from "./Styles";

export default function Phonecall() {
  const navigate = useNavigate();
  const productName = useSelector(getProductName);
  const { register, errors, triggerValidation, watch } = useForm({
    mode: "onBlur",
  });
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });

  const [orderPhonecall, { loading }] = useMutation(ORDER_PHONECALL, {
    onCompleted: (data) => {
      if (data?.orderPhoneCall?.success) {
        window.analytics.track("Callback requested");
        navigate("/phonecall/result");
      } else {
        navigate("/error", { 
          state: { 
            errorMessage: "Mistókst að panta símtal"
          }
        });
      }
    },
    onError: () => navigate("/error", { 
      state: { 
        errorMessage: "Mistókst að panta símtal" 
      }
    }),
  });

  useEffect(() => {
    window.analytics.page("Phonecall");
    async function init() {
      await triggerValidation();
    }
    init();
  }, [triggerValidation]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Layout.CenteredContainer>
      <Container>
        <ActionContainer>
          {isTabletOrMobile && <ProductImage />}
          <LargeHeader>Pöntun á símtali</LargeHeader>
          <SubHeader>Upplýsingar um þig</SubHeader>
          <Layout.Break size={"20px"} />
          <NormalText>
            Vinsamlegast fylltu út þær upplýsingar sem við þurfum til þess að
            hafa samband við þig.
          </NormalText>
          <Layout.Break size={"20px"} />
          <Form>
            <FormItem>
              <LabelText>Fullt nafn*</LabelText>
              <Input
                name="name"
                type="text"
                valid={!errors.name}
                ref={register({
                  required: true,
                  minLength: { value: 2, message: "Nafn of stutt." },
                  pattern: {
                    value: /^[a-zA-ZÁáÐðÉéÚúÍíÓóÝýÞþÆæÖö\s]*$/,
                    message: "Nafn má aðeins innihalda bókstafi.",
                  },
                })}
              />
              <InputError message={errors?.name?.message} />
            </FormItem>
            <FormItem>
              <LabelText>Kennitala*</LabelText>
              <Input
                name="nationalId"
                type="text"
                maxLength={10}
                valid={!errors.nationalId}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Kennitala þarf að vera 10 tölustafir",
                  },
                })}
              />
              <InputError message={errors?.nationalId?.message} />
            </FormItem>
            <FormItem>
              <LabelText>Símanúmer*</LabelText>
              <Input
                name="phonenumber"
                type="text"
                maxLength={7}
                valid={!errors.phonenumber}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\d{7}$/,
                    message: "Símanúmer ekki á réttu formi.",
                  },
                })}
              />
              <InputError message={errors?.phonenumber?.message} />
            </FormItem>
            <FormItem>
              <LabelText>Netfang*</LabelText>
              <Input
                name="email"
                type="email"
                valid={!errors.email}
                ref={register({
                  required: true,
                  validate: (value) => isEmail(value),
                })}
              />
              <InputError
                message={
                  errors?.email?.type === "validate"
                    ? "Netfang ekki á réttu formi"
                    : ""
                }
              />
            </FormItem>
            <Button
              active={
                !errors.phonenumber && !errors.name && !errors.email && !loading
              }
              width={100}
              onClick={async (e) => {
                e.preventDefault();
                const valid = await triggerValidation();
                if (valid && !loading) {
                  const { phonenumber, name, email, nationalId } = watch();
                  orderPhonecall({
                    variables: {
                      productName: productName ?? "Sjónvarpsþjónustu",
                      phoneNumber: phonenumber,
                      fullName: name,
                      email,
                      nationalId,
                    },
                  });
                }
              }}
            >
              PANTA SÍMTAL
            </Button>
          </Form>
        </ActionContainer>
        {!isTabletOrMobile && <ProductImage />}
      </Container>
    </Layout.CenteredContainer>
  );
}
