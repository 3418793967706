import React, { useState } from "react";
import arrow from "../../../images/arrow-right.svg";
import {
  ToggleContainer,
  ToggleIcon,
  ToggleBox,
  ToggleContent,
  ToggleMargin,
} from "./Styles";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import { useSelector } from "react-redux";

export default function Toggle({
  component,
  label,
  disabled,
  toggled = false,
}) {
  const [toggle, setToggle] = useState(toggled);
  const theme = useSelector(getTheme);
  return (
    <ToggleMargin>
      <ToggleContainer
        disabled={disabled}
        onClick={(e) => (disabled ? e.preventDefault() : setToggle(!toggle))}
      >
        <ToggleBox theme={theme} disabled={disabled}>
          <ToggleIcon toggled={toggle} src={arrow} alt="toggle content" />
        </ToggleBox>
        {label}
      </ToggleContainer>
      {toggle && <ToggleContent>{component}</ToggleContent>}
    </ToggleMargin>
  );
}
