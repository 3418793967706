import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import ProductImage from "../Shared/ProductImage/ProductImage";
import { Layout } from "../Shared/Shared";
import EidForm from "./EidForm";
import PhonecallInfo from "./PhonecallInfo";

const { Container, ActionContainer, RulerSpacy, CenteredContainer } = Layout;

export default function Login() {
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });
  useEffect(() => {
    window.analytics.page("Login");
    window.analytics.track("Login Started");
  });
  return (
    <CenteredContainer>
      <Container>
        <ActionContainer>
          {isTabletOrMobile && <ProductImage />}
          <EidForm />
          <RulerSpacy space={"10px"} />
          <PhonecallInfo />
        </ActionContainer>
        {!isTabletOrMobile && <ProductImage />}
      </Container>
    </CenteredContainer>
  );
}
