import styled from "styled-components";
import { MOBILE_BREAKPOINT, THEME } from "../../../utility/constants";
import { color } from "../../Shared/Color/colors";

export const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => (props.theme === THEME.VODAFONE ? "none" : "100%")};
  background: ${(props) =>
    props.theme === THEME.VODAFONE ? color.white : "none"};
  box-shadow: ${(props) =>
    props.theme === THEME.VODAFONE
      ? `0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)`
      : "none"};
`;
export const InnerContainer = styled.div`
  padding: ${(props) => (props.theme === THEME.VODAFONE ? "20px" : "0px")};
  max-width: ${(props) => (props.theme === THEME.VODAFONE ? "37%" : "none")};
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: ${(props) => (props.theme === THEME.VODAFONE ? "10px" : "0px")};
    max-width: ${(props) => (props.theme === THEME.VODAFONE ? "100%" : "none")};
  }
`;
