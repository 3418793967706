import React, { useState } from "react";
import error from "../../../images/icon-failed-red.svg";
import info from "../../../images/icon-info.svg";
import warning from "../../../images/icon-warning-yellow.svg";
import close from "../../../images/vf-status-inactive.svg";
import success from "../../../images/icon-success-green.svg";
import {
  Alert,
  AlertHeader,
  AlertText,
  AlertTextContainer,
  Close,
  Container,
  Icon,
} from "./Styles";
import { useMediaQuery } from "react-responsive";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";

export default function AlertWrap({
  level,
  header,
  body,
  animated = false,
  closeable = false,
}) {
  const [show, setShow] = useState(true);
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });
  let image;
  if (level === "error") {
    image = error;
  } else if (level === "warning") {
    image = warning;
  } else if (level === "info") {
    image = info;
  } else if (level === "success") {
    image = success;
  } else {
    return null;
  }

  return show ? (
    <Alert level={level} animated={animated}>
      {closeable && (
        <Close>
          <img src={close} onClick={() => setShow(!show)} alt="Loka viðvörun" />
        </Close>
      )}
      <Container>
        {!isTabletOrMobile && (
          <Icon>
            <img src={image} alt={level} />
          </Icon>
        )}
        <AlertTextContainer>
          {header && <AlertHeader>{header}</AlertHeader>}
          <AlertText>{body}</AlertText>
        </AlertTextContainer>
      </Container>
    </Alert>
  ) : null;
}
