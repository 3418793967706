import styled, { keyframes } from "styled-components";
import { color } from "../Color/colors";
import { zoomIn } from "react-animations";
import { THEME } from "../../../utility/constants";

const zoomInAnimation = keyframes`${zoomIn}`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  line-height: 26px;
  color: ${(props) => (props.disabled ? color.darkGrey : color.nero)};
`;

const ToggleBox = styled.div`
  display: flex;
  width: 26px;
  height: 26px;
  background: ${(props) =>
    props.disabled
      ? color.darkGrey
      : props.theme === THEME.VODAFONE
      ? color.vodaRed
      : color.greenish};
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-right: 11px;
  border-radius: 5px;
`;

const ToggleIcon = styled.img`
  display: flex;
  width: 20px;
  height: 20px;
  transition: 0.15s ease-in-out;
  transform: ${(props) => (props.toggled ? "rotate(90deg)" : "rotate(0deg)")};
`;

const ToggleContent = styled.div`
  display: flex;
  margin-top: 25px;
  width: 100%;
  animation: ${zoomInAnimation} 300ms;
`;

const ToggleMargin = styled.div`
  margin-bottom: 30px;
`;

export { ToggleContainer, ToggleIcon, ToggleBox, ToggleContent, ToggleMargin };
