const prefix = "process/";
const SET_PROCESS = `${prefix}SET_PROCESS`;
const SET_PROCESS_STATUS = `${prefix}SET_PROCESS_STATUS`;
const SET_PROCESS_ERROR = `${prefix}SET_PROCESS_ERROR`;
const PROCESS_INCREMENT_POLL_COUNT = `${prefix}PROCESS_INCREMENT_POLL_COUNT`;
const PROCESS_CLEAR_POLL_COUNT = `${prefix}PROCESS_CLEAR_POLL_COUNT`;

const setProcess = (process) => ({
  type: SET_PROCESS,
  process,
});

const setProcessStatus = (status) => ({
  type: SET_PROCESS_STATUS,
  status,
});

const setProcessError = (error) => ({
  type: SET_PROCESS_ERROR,
  error,
});

const clearPollCount = () => ({ type: PROCESS_CLEAR_POLL_COUNT });

const incrementPollCount = () => ({
  type: PROCESS_INCREMENT_POLL_COUNT,
});

export {
  SET_PROCESS,
  SET_PROCESS_STATUS,
  SET_PROCESS_ERROR,
  PROCESS_CLEAR_POLL_COUNT,
  PROCESS_INCREMENT_POLL_COUNT,
  setProcess,
  setProcessStatus,
  setProcessError,
  clearPollCount,
  incrementPollCount,
};
