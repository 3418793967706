import styled, { keyframes } from "styled-components";
import { color } from "../Shared/Color/colors";
import { MOBILE_BREAKPOINT, LAYOUT_MAX_WIDTH } from "../../utility/constants";
import { fadeIn } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;

const ResultContainer = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
  background: ${color.backgroundGrey};
  animation: 2s ${fadeInAnimation};
  max-width: ${LAYOUT_MAX_WIDTH};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 20px;
  }
`;

const LinkBoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1em;
  grid-auto-rows: auto;
  width: 100%;
  margin-top: 40px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-around;
    margin-bottom: auto;
    margin-top: auto;
  }
`;

const LinkBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  background: ${color.white};
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0 2px 14px 0 rgba(0, 0, 0, 0.12);

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    :first-child {
      margin-top: 28px;
    }
    margin-bottom: 28px;
  }
`;

const LinkBoxImage = styled.img`
  width: 100%;
`;

const LinkBoxTextContainer = styled.div`
  padding: 20px 25px 20px 25px;
`;

const LinkBoxHeader = styled.div`
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  color: ${color.vulcan};
  margin-bottom: 10px;
`;

const LinkBoxText = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  min-height: 120px;
  margin-bottom: 15px;
  color: ${color.vulcan};
`;

export {
  ResultContainer,
  LinkBoxGrid,
  LinkBoxItem,
  LinkBoxTextContainer,
  LinkBoxHeader,
  LinkBoxText,
  LinkBoxImage,
};
