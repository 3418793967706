import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`${fadeIn}`;

const InputErrorWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const InputErrorContainer = styled(InputErrorWrap)`
  margin-top: 0;
  animation: 1s ${fadeInAnimation};
`;

const ErrorIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export { InputErrorWrap, ErrorIcon, InputErrorContainer };
