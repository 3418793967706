import { SET_TOGGLE } from "./actions";

const initialState = {
  toggle: false,
};

export default function toggleReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOGGLE:
      return {
        ...state,
        toggle: action.toggle,
      };
    default:
      return state;
  }
}
