import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "apollo-boost";

import { checkValidToken } from "./utility/auth";

const httpLink = new HttpLink({
  uri: "/graphql",
});

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext(() => {
    const token = checkValidToken();
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  name: 'order-web',
  version: '1.0'
});

export default client;