import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";
import { color } from "../../Shared/Color/colors";

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 20px;
  }
`;

const SummaryLabel = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
  color: ${color.black};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 5px;
  }
`;

const DonationSummaryGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr);` : `repeat(4, 1fr);`};
  grid-auto-rows: auto;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
  }
`;

export { SummaryItem, SummaryLabel, DonationSummaryGrid };