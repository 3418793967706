import {
  applyMiddleware,
  compose,
  createStore
} from "redux";
import {
  composeWithDevTools
} from "redux-devtools-extension";
import monitorReducersEnhancer from "./enhancers/monitorReducers";
import loggerMiddleware from "./middlewares/logger";
import rootReducer from "./reducers/rootReducer";
import raygunBreadcrumb from "./middlewares/raygunBreadcrumb";
import { isDevelopment } from "../utility/env";
// HUG-143 replaced by eCommerce tracking, this middleware was way too verbose.
// import segmentMiddleWare from "./middlewares/segmentMiddleware";

export default function configureStore(preloadedState) {
  const middlewares = isDevelopment() ? [loggerMiddleware, raygunBreadcrumb] : [raygunBreadcrumb];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = isDevelopment() ? [middlewareEnhancer, monitorReducersEnhancer] : [middlewareEnhancer];

  const composedEnhancers = isDevelopment() ?
    composeWithDevTools(...enhancers) :
    compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}