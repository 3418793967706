const prefix = "user/";
const SET_USER = `${prefix}SET_USER`;
const SET_USER_PHONENUMBER = `${prefix}SET_USER_PHONENUMBER`;
const SET_USER_EMAIL = `${prefix}SET_USER_EMAIL`;
const SET_USER_NAME = `${prefix}SET_USER_NAME`;
const SET_USER_CONTENT_TERMS = `${prefix}SET_USER_CONTENT_TERMS`;
const SET_USER_EMAIL_CONFIRMED = `${prefix}SET_USER_EMAIL_CONFIRMED`;
const SET_GDPR_TERMS = `${prefix}SET_GDPR_TERMS`;
const SET_USER_HAS_OTT = `${prefix}SET_USER_HAS_OTT`;
const SET_USER_TV_EQUIPMENT_COUNT = `${prefix}SET_USER_TV_EQUIPMENT_COUNT`;
const SET_USER_TV_SYSTEM_PROPERTIES = `${prefix}SET_USER_TV_SYSTEM_PROPERTIES`;
const CLEAR_USER = `${prefix}CLEAR_USER`;
const SET_BILLING_ID = `${prefix}SET_BILLING_ID`;
const SET_PAYMENT_TYPE_CONFIRMED = `${prefix}SET_PAYMENT_TYPE_CONFIRMED`;
const SET_SELECTED_PAYMENT_TYPE = `${prefix}SET_SELECTED_PAYMENT_TYPE`;
const SET_DISTRIBUTION = `${prefix}SET_DISTRIBUTION`;
const SET_AUTHENTICATED = `${prefix}SET_AUTHENTICATED`;
const SET_HAS_DISCOUNT = `${prefix}SET_HAS_DISCOUNT`;
const SET_HAS_CHANNEL_CONFLICT = `${prefix}SET_HAS_CHANNEL_CONFLICT`;
const SET_USER_CONTENT = `${prefix}SET_USER_CONTENT`;
const SET_DISPLAY_VIRTUAL_NUMBER_PAGE = `${prefix}SET_DISPLAY_VIRTUAL_NUMBER_PAGE`;
const SET_VIRTUAL_NUMBER_PAGE_FINISHED = `${prefix}SET_VIRTUAL_NUMBER_PAGE_FINISHED`;
const SET_PAYMENT_TYPE_SELECTED_ENABLED = `${prefix}SET_PAYMENT_TYPE_SELECTED_ENABLED`;
const SET_VIRTUAL_PAGE_LINK = `${prefix}SET_VIRTUAL_PAGE_LINK`;
const SET_FETCH_VIRTUAL_NUMBER_PAGE = `${prefix}SET_FETCH_VIRTUAL_NUMBER_PAGE`;

const setUser = (user) => ({
  type: SET_USER,
  user,
});

const setUserPhonenumber = (phonenumber) => ({
  type: SET_USER_PHONENUMBER,
  phonenumber,
});

const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  email,
});

const setUserName = (name) => ({
  type: SET_USER_NAME,
  name,
});

const setContentTerms = (accepted) => ({
  type: SET_USER_CONTENT_TERMS,
  accepted,
});

const setGDPRTerms = (accepted) => ({
  type: SET_GDPR_TERMS,
  accepted,
});

const setEmailConfirmed = (accepted) => ({
  type: SET_USER_EMAIL_CONFIRMED,
  accepted,
});

const setUserHasOtt = (hasOtt) => ({
  type: SET_USER_HAS_OTT,
  hasOtt,
});

const setUserTvEquipmentCount = (count) => ({
  type: SET_USER_TV_EQUIPMENT_COUNT,
  count,
});

const setUserTvSystemProperties = (hasOtt, tvEquipmentCount) => ({
  type: SET_USER_TV_SYSTEM_PROPERTIES,
  hasOtt,
  tvEquipmentCount,
});

const setBillingId = (billingId, currentPaymentType, maskedCreditCard, cardType) => ({
  type: SET_BILLING_ID,
  billingId,
  currentPaymentType,
  maskedCreditCard,
  cardType
});
const setPaymentTypeConfirmed = (paymentTypeConfirmed) => ({
  type: SET_PAYMENT_TYPE_CONFIRMED,
  paymentTypeConfirmed,
});
const setVirtualPageLink = (billingId, url) => ({
  type: SET_VIRTUAL_PAGE_LINK,
  billingId,
  url,
});
const setDisplayVirtualNumberPage = (display) => ({
  type: SET_DISPLAY_VIRTUAL_NUMBER_PAGE,
  display,
});
const setVirtualNumberPageFinished = () => ({
  type: SET_VIRTUAL_NUMBER_PAGE_FINISHED,
});

const setDistribution = (providerCustomerId, providerType) => ({
  type: SET_DISTRIBUTION,
  providerCustomerId,
  providerType,
});

const setHasDiscount = (value) => ({
  type: SET_HAS_DISCOUNT,
  value,
});

const setAuthenticated = (authenticated) => ({
  type: SET_AUTHENTICATED,
  authenticated,
});

const clearUser = () => ({
  type: CLEAR_USER,
});

const setHasChannelConflict = (conflict) => ({
  type: SET_HAS_CHANNEL_CONFLICT,
  conflict,
});

const setUserContent = (content) => ({
  type: SET_USER_CONTENT,
  content,
});
const setPaymentTypeSelectEnabled = () => ({
  type: SET_PAYMENT_TYPE_SELECTED_ENABLED,
});
const setFetchVirtualNumberPage = (fetchVirtualNumberPage) => ({
  type: SET_FETCH_VIRTUAL_NUMBER_PAGE,
  fetchVirtualNumberPage,
});
const setSelectedPaymentType = (selectedPaymentType) => ({
  type: SET_SELECTED_PAYMENT_TYPE,
  selectedPaymentType
});

export {
  SET_USER,
  SET_USER_PHONENUMBER,
  SET_USER_EMAIL,
  SET_USER_NAME,
  SET_USER_CONTENT_TERMS,
  SET_GDPR_TERMS,
  SET_USER_HAS_OTT,
  SET_USER_TV_EQUIPMENT_COUNT,
  SET_USER_TV_SYSTEM_PROPERTIES,
  CLEAR_USER,
  SET_BILLING_ID,
  SET_DISTRIBUTION,
  SET_AUTHENTICATED,
  SET_HAS_DISCOUNT,
  SET_HAS_CHANNEL_CONFLICT,
  SET_USER_CONTENT,
  SET_DISPLAY_VIRTUAL_NUMBER_PAGE,
  SET_VIRTUAL_NUMBER_PAGE_FINISHED,
  SET_PAYMENT_TYPE_SELECTED_ENABLED,
  SET_VIRTUAL_PAGE_LINK,
  SET_FETCH_VIRTUAL_NUMBER_PAGE,
  SET_PAYMENT_TYPE_CONFIRMED,
  SET_SELECTED_PAYMENT_TYPE,
  SET_USER_EMAIL_CONFIRMED,
  setUser,
  setUserPhonenumber,
  setUserEmail,
  setUserName,
  setContentTerms,
  setGDPRTerms,
  setUserHasOtt,
  setUserTvEquipmentCount,
  setUserTvSystemProperties,
  clearUser,
  setBillingId,
  setDistribution,
  setAuthenticated,
  setHasDiscount,
  setHasChannelConflict,
  setUserContent,
  setDisplayVirtualNumberPage,
  setVirtualNumberPageFinished,
  setPaymentTypeSelectEnabled,
  setVirtualPageLink,
  setFetchVirtualNumberPage,
  setPaymentTypeConfirmed,
  setSelectedPaymentType,
  setEmailConfirmed
};
