import {
  SET_USER,
  SET_USER_CONTENT_TERMS,
  SET_USER_EMAIL,
  SET_USER_NAME,
  SET_GDPR_TERMS,
  SET_USER_PHONENUMBER,
  SET_USER_HAS_OTT,
  SET_USER_TV_EQUIPMENT_COUNT,
  SET_USER_TV_SYSTEM_PROPERTIES,
  CLEAR_USER,
  SET_BILLING_ID,
  SET_PAYMENT_TYPE_CONFIRMED,
  SET_SELECTED_PAYMENT_TYPE,
  SET_DISTRIBUTION,
  SET_AUTHENTICATED,
  SET_HAS_DISCOUNT,
  SET_HAS_CHANNEL_CONFLICT,
  SET_USER_CONTENT,
  SET_DISPLAY_VIRTUAL_NUMBER_PAGE,
  SET_VIRTUAL_NUMBER_PAGE_FINISHED,
  SET_PAYMENT_TYPE_SELECTED_ENABLED,
  SET_VIRTUAL_PAGE_LINK,
  SET_FETCH_VIRTUAL_NUMBER_PAGE,
  SET_USER_EMAIL_CONFIRMED
} from "./actions";

const initialState = {
  phonenumber: null,
  email: null,
  fullName: null,
  nationalID: null,
  contentTerms: false,
  gdprTerms: false,
  ott: false,
  tvEquipmentCount: 0,
  authenticated: false,
  channelConflict: false,
  content: null,
  gdprAccepted: false,
  emailConfirmed: false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user,
      };
    case SET_USER_PHONENUMBER:
      return {
        ...state,
        phonenumber: action.phonenumber,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_USER_NAME:
      return {
        ...state,
        fullName: action.name,
      };
    case SET_USER_CONTENT_TERMS:
      return {
        ...state,
        contentTerms: action.accepted,
      };
    case SET_USER_EMAIL_CONFIRMED:
      return {
        ...state,
        emailConfirmed: action.accepted,
      };
    case SET_GDPR_TERMS:
      return {
        ...state,
        gdprTerms: action.accepted,
      };
    case SET_USER_HAS_OTT:
      return {
        ...state,
        ott: action.hasOtt,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case SET_USER_TV_EQUIPMENT_COUNT:
      return {
        ...state,
        tvEquipmentCount: action.count,
      };
    case SET_USER_TV_SYSTEM_PROPERTIES:
      return {
        ...state,
        tvEquipmentCount: action.tvEquipmentCount,
        ott: action.hasOtt,
      };
    case CLEAR_USER:
      return {
        ...state,
        ...initialState,
      };
    case SET_BILLING_ID:
      return {
        ...state,
        billingId: action.billingId,
        currentPaymentType: action.currentPaymentType,
        paymentTypeLoaded: true,
        maskedCreditCard: action.maskedCreditCard,
        cardType: action.cardType
      };
    case SET_PAYMENT_TYPE_CONFIRMED:
      return {
        ...state,
        paymentTypeConfirmed: action.paymentTypeConfirmed,
      };
    case SET_SELECTED_PAYMENT_TYPE:
      return {
        ...state,
        selectedPaymentType: action.selectedPaymentType,
      };
    case SET_VIRTUAL_PAGE_LINK:
      return {
        ...state,
        virtualNumberBillingId: action.billingId,
        virtualNumberUrl: action.url,
      };
    case SET_DISTRIBUTION:
      return {
        ...state,
        providerCustomerId: action.providerCustomerId,
        providerType: action.providerType,
        distributionLoaded: true,
      };
    case SET_HAS_DISCOUNT:
      return {
        ...state,
        hasDiscount: action.value,
      };
    case SET_HAS_CHANNEL_CONFLICT:
      return {
        ...state,
        channelConflict: action.conflict,
      };
    case SET_USER_CONTENT:
      return {
        ...state,
        content: action.content,
      };
    case SET_DISPLAY_VIRTUAL_NUMBER_PAGE:
      return {
        ...state,
        displayVirtualNumberPage: action.display,
      };
    case SET_FETCH_VIRTUAL_NUMBER_PAGE:
      return {
        ...state,
        fetchVirtualNumberPage: action.fetchVirtualNumberPage,
      };
    case SET_PAYMENT_TYPE_SELECTED_ENABLED:
      return {
        ...state,
        paymentTypeSelectEnabled: true,
      };
    case SET_VIRTUAL_NUMBER_PAGE_FINISHED:
      return {
        ...state,
        displayVirtualNumberPage: false,
        paymentTypeLoaded: false,
        virtualNumberPageFinished: true,
        billingId: state.virtualNumberBillingId, // billingId replaced with new billingId
      };
    default:
      return state;
  }
}
