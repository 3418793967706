import { slideInDown } from "react-animations";
import styled, { keyframes } from "styled-components";
import { MOBILE_BREAKPOINT, THEME } from "../../../utility/constants";
import { SubHeader } from "../Typography/Header";
import { color } from "../Color/colors";

const fadeInDownAnimation = keyframes`${slideInDown}`;

const MenuIcon = styled.img`
  width: 38px;
  height: 32px;
  z-index: 9999;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-right: 20px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  background: rgba(32, 82, 87, 0.95);
  background: ${(props) =>
    props.theme === THEME.VODAFONE
      ? "rgba(255, 255, 255, 0.95)"
      : "rgba(32, 82, 87, 0.95)"};
  animation: 750ms ${fadeInDownAnimation};
  position: fixed;
`;

const MenuSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -100px;
  width: 100%;
  height: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: -64px;
  }
`;

const MenuHeader = styled(SubHeader)`
  margin-bottom: 10px;
  color: ${(props) =>
    props.theme === THEME.VODAFONE ? color.nero : color.white};
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 24px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

export {
  MenuIcon,
  MenuContainer,
  MenuSubContainer,
  MenuHeader,
  ButtonContainer,
};
