import React from "react";
import Error from "../../../images/icon-failed-red.svg";
import { ErrorText } from "../Typography/Text";
import { ErrorIcon, InputErrorContainer, InputErrorWrap } from "./Styles";

export default function InputError({ message }) {
  return (
    <InputErrorWrap>
      {message && (
        <InputErrorContainer>
          <ErrorIcon src={Error} />
          <ErrorText>{message}</ErrorText>
        </InputErrorContainer>
      )}
    </InputErrorWrap>
  );
}
