import { isDevelopment } from "../../src/utility/env";

const PROCESS_SUCCESS = "200";
const PROCESS_ACTION_CREATE = "create";
const PROCESS_ACTION_DELETE = "delete";
const TERMS_URL_TV_CONTENT = "https://stod2.is/askriftarskilmalar";
const MINAR_SIDUR_URL = "https://minar.vodafone.is/#/services/tvcontent/detail";
const STOD2_REDIRECT = "https://stod2.is/askrift/askriftarpakkar";
const VODAFONE_REDIRECT = "https://vodafone.is";

const EXPERIENCE_PUBLIC_URL = isDevelopment()
  ? "https://syn-staging-experience-public.ir-e1.cloudhub.io/api/customer/"
  : "https://api.syn.is/experience/public/customer/";

const EXPERIENCE_SELF_SERVICE_URL = isDevelopment()
  ? "https://syn-staging-experience-selfservice.ir-e1.cloudhub.io/api/"
  : "https://api.syn.is/experience/selfservice/";

const PROCESS_STATUS = {
  DONE: "DONE",
  IN_PROCESS: "INPROCESS",
  PUBLISHED: "PUBLISHED",
};
const PROCESS_ONGOING_STATUSES = [
  PROCESS_STATUS.IN_PROCESS,
  PROCESS_STATUS.PUBLISHED,
];
const MOBILE_SMALL_BREAKPOINT = "768px";
const MOBILE_BREAKPOINT = "1025px";
const LAYOUT_MAX_WIDTH = "1210px";
const SUBSCRIPTION_TYPE = {
  TV: "TV",
  CONTENT: "CONTENT",
};
const THEME = {
  STOD2: "stod2",
  VODAFONE: "vodafone",
};
const ELIGIBLE_FOR_NFL_AND_NBA = [
  "41225", // marathonhappihelgi
  "36464", // marathon_now
  "40422", // sport_island
  "40423", // sport_island6man
  "40915", // sport_islandvinnumsaman
  "40983", // sport_erlent
  "36466", // stod_2_stok_ras
  "41319", // stod_2
  "41386", // stod_2_pakkinn
  "37753", // stod2marathonplus
  "35899", // risapakkinn
  "39923", // risapakkinn6man
  "41401", // risapakkinn_pakkinn
  "39920", // risapakkinn12man
  "36460", // sportpakkinn
  "39971", // sportpakkinn6man
  "41400", // sportpakkinn_pakkinn
  "36459", // skemmtipakkinn
  "39919", // skemmtipakkinn12man
  "39921", // skemmtipakkinn6man
  "38924", // storipakkinn
  "39925", // storipakkinn6man
];

const ELIGIBLE_FOR_NFL_01_09_22 = [
  "41225", // marathonhappihelgi
  "36464", // marathon_now
  "40422", // sport_island
  "40423", // sport_island6man
  "40983", // sport_erlent
  "41319", // stod_2
  "41386", // stod_2_pakkinn
  "36466", // stod_2_stok_ras
  "42665", // stod2plus_sportpakkinn
  "42664", // stod2plus_sportisland
  "36460", // sportpakkinn
  "39971", // sportpakkinn6man
  "41733", // sportpakkinn_pakkinn
  "36459", // skemmtipakkinn
  "39919", // skemmtipakkinn12man
  "39921", // skemmtipakkinn6man
  "36473", // fyrirtaekjapakkinn
  "42666", // stod2_sport
  "41400", // sportpakkinn_pakkinn
  "38924", // storipakkinn
  "39925", // storipakkinn6man
  "40941", // storipakkinnsumarvinnumsaman
  "35899", // risapakkinn
  "39923", // risapakkinn6man
  "39920", // risapakkinn12man
  "41401", // risapakkinn_pakkinn
  "37754", // storipakkinn_sumar
];

const ELIGIBLE_FOR_INTERNET_PLUS_BUNDLE = [
  "43459", // marathon_now_internet_plus
];

const ALREADY_HAS_INTERNET_PLUS_BUNDLE = [
  "43460", // stod_2_internet_plus
];

const PAYMENT_TYPES = {
  ELECTRONIC: "ELECTRONIC",
  CREDITCARD: "CREDITCARD",
  NEW_CARD: "NEW_CARD",
};

const ERROR_MESSAGES = {
  emptyCardNumber: "Vantar kortanúmer",
  invalidCardNumber: "Kortanúmer er ógilt",
  emptyExpiryDate: "Vantar gildistíma",
  monthOutOfRange: "Mánuður verður að vera á milli 01 og 12",
  yearOutOfRange: "Gildisár getur ekki verið í fortíðinni",
  dateOutOfRange: "Gildistími getur ekki verið í fortíðinni",
  invalidExpiryDate: "Gildistími ógildur",
  emptyCVC: "Vantar CVC númer",
  invalidCVC: "CVC númer er ógilt",
};

// Max 60 seconds
const CREDITCARD_POLL_INTERVAL = 1000;
const MAX_CREDITCARD_POLL_COUNT = 60;

export {
  PROCESS_SUCCESS,
  PROCESS_ACTION_CREATE,
  TERMS_URL_TV_CONTENT,
  PROCESS_STATUS,
  PROCESS_ONGOING_STATUSES,
  MINAR_SIDUR_URL,
  MOBILE_BREAKPOINT,
  MOBILE_SMALL_BREAKPOINT,
  SUBSCRIPTION_TYPE,
  LAYOUT_MAX_WIDTH,
  STOD2_REDIRECT,
  PROCESS_ACTION_DELETE,
  THEME,
  VODAFONE_REDIRECT,
  ELIGIBLE_FOR_NFL_AND_NBA,
  ELIGIBLE_FOR_NFL_01_09_22,
  ELIGIBLE_FOR_INTERNET_PLUS_BUNDLE,
  ALREADY_HAS_INTERNET_PLUS_BUNDLE,
  PAYMENT_TYPES,
  ERROR_MESSAGES,
  EXPERIENCE_PUBLIC_URL,
  EXPERIENCE_SELF_SERVICE_URL,
  CREDITCARD_POLL_INTERVAL,
  MAX_CREDITCARD_POLL_COUNT,
};
