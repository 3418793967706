import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LabelText } from "../../Shared/Shared";
import { Input } from "../../Shared/Input/Input";
import InputError from "../../Shared/Input/InputError";
import {
  RowItems,
  RowItem,
  CreditCardInput,
  CreditCardInputContainer,
  CreditCardSvg,
  Form,
} from "../PaymentForm/Styles";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { ERROR_MESSAGES } from "../../../utility/constants";
import { setPaymentTypeConfirmed } from "../../../redux/reducers/user/actions";

export default function CreditCardForm(props) {
  const dispatch = useDispatch();
  const [cardValid, setCardValid] = useState(false);
  const [cardInformation, setCardInformation] = useState({
    cardNumber: "",
    expiryDate: "",
    cvc: "",
  });

  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
    onChange: (e) => handleOnChange(e),
    onError: (error, erroredInputs) => handleOnError(error, erroredInputs),
    onBlur: (e) => callbackCardInfoToParent(),
  });

  const handleOnChange = (e) => {
    setCardInformation({
      ...cardInformation,
      [e.target.name]: e.target.value,
    });
  };

  const callbackCardInfoToParent = () => {
    props.callback(cardInformation);
  };

  const handleOnError = (error, erroredInputs) => {
    setCardValid(!isErrorOnCard(erroredInputs));
  };

  const isErrorOnCard = (erroredInputs) => {
    return (
      erroredInputs.cardNumber || erroredInputs.expiryDate || erroredInputs.cvc
    );
  };

  useEffect(() => {
    if (cardValid) {
      dispatch(setPaymentTypeConfirmed(true));
    } else {
      dispatch(setPaymentTypeConfirmed(false));
    }
  }, [cardValid]);

  return (
    <Form>
      <RowItem marginTop={"20px"}>
        <LabelText>Kreditkortanúmer</LabelText>
        <CreditCardInputContainer>
          <CreditCardSvg {...getCardImageProps({ images })} />
          <CreditCardInput
            {...getCardNumberProps()}
            placeholder={"**** **** **** ****"}
            maxLength={19}
          />
        </CreditCardInputContainer>
      </RowItem>
      <RowItems>
        <RowItem width={"45%"}>
          <LabelText>Gildistími</LabelText>
          <Input
            {...getExpiryDateProps()}
            maxWidth={"100"}
            maxLength={7}
            placeholder="MM/ÁÁ"
            width={"100%"}
            boxSizing={"border-box"}
          />
        </RowItem>
        <RowItem width={"45%"} marginBottom={"20px"}>
          <LabelText>CVC</LabelText>
          <Input
            {...getCVCProps()}
            maxWidth={"100"}
            width={"100%"}
            boxSizing={"border-box"}
          />
        </RowItem>
      </RowItems>
      {meta.isTouched && meta.error && <InputError message={meta.error} />}
    </Form>
  );
}
