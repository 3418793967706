import { gql } from "apollo-boost";
import { checkValidToken } from "../../utility/auth";
import { EXPERIENCE_SELF_SERVICE_URL } from "../../utility/constants";

export const START_ORDER_CONTENT_PROCESS = gql`
  mutation startContentProcess($contentOrders: [ContentOrder]) {
    startContentProcess(contentOrders: $contentOrders) {
      status
      reason
      orderId
    }
  }
`;

export const MODIFY_CUSTOMER = gql`
  mutation modifyCustomer($modification: Modification) {
    modifyCustomer(modification: $modification) {
      id
    }
  }
`;

export const putCreditCard = async (creditCardInfo) => {
  const token = checkValidToken();

  const fullYear = '20' + creditCardInfo.expiryDate.split('/')[1].trim();

  const response = await fetch(EXPERIENCE_SELF_SERVICE_URL + 'customer/creditCard', {
    method: 'PUT',
    headers: {
      'Authorization': token ? `Bearer ${token}` : "",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cardNumber: creditCardInfo.cardNumber,
      expiryDate: {
        month: creditCardInfo.expiryDate.split('/')[0].trim(),
        year: fullYear
      },
      cvc: creditCardInfo.cvc,
      redirectUrl: window.location.href,
      source: 'kaup.stod2.is'
    })
  });

  if (!response.ok) {
    throw new Error(response.json());
  }

  return response.json();
}

export const patchPaymentMethod = async (paymentMethod) => {
  const token = checkValidToken();

  const response = await fetch(EXPERIENCE_SELF_SERVICE_URL + 'customer/paymentMethod', {
    method: 'PATCH',
    headers: {
        'Authorization': token ? `Bearer ${token}` : "",
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      tv: paymentMethod
    })
  });

  if (!response.ok) {
    throw new Error(response.json());
  }

  return response.json();
}
