import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../../redux/reducers/product/selectors";
import { setSubscriptions } from "../../../redux/reducers/product/actions";
import { setUserContent } from "../../../redux/reducers/user/actions";
import { MINAR_SIDUR_URL } from "../../../utility/constants";
import AlertWrap from "../../Shared/Alert/AlertWrap";
import LinkButton from "../../Shared/Link/Link";
import { Spinner } from "../../Shared/Shared";
import { GET_PRODUCT_CONFLICTS } from "./queries";

export default function ChannelConflict(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector(getProduct);

  const { handleConflict } = props;
  const { data, loading } = useQuery(GET_PRODUCT_CONFLICTS, {
    variables: {
      otherProductId: Number(product.id),
    },
    onCompleted: (data) => {
      dispatch(setSubscriptions(data.subscriptions));
    },
    onError: () =>
      navigate("/error", {
        state: {
          errorMessage: "Mistókst að sækja vöru ágreining",
        },
      }),
  });

  // // má henda þessu?
  // useEffect(() => {
  //   if (data?.subscriptions?.length > 0) {
  //     dispatch(
  //       setUserContent(data.subscriptions.map((sub) => sub.product.name))
  //     );
  //   }
  // }, [data, dispatch]);

  if (data?.subscriptions?.length > 0) {
    let sub = getFirstConflict(data.subscriptions);
    if (sub?.productConflict?.sameChannels?.length > 0) {
      handleConflict(true);
      return null;
    }
    return null;
  }

  return null;
}
// its problably not worth it to list every conflict with every package the customer has, just show the first one
function getFirstConflict(subs) {
  for (const sub of subs) {
    if (sub?.productConflict?.sameChannels?.length > 0) {
      return sub;
    }
  }
  return null;
}
