import { gql } from "apollo-boost";

const GET_PROCESS_STATUS = gql`
  query process($orderId: Int!) {
    process(orderId: $orderId) {
      orderId
      status
    }
  }
`;

export { GET_PROCESS_STATUS };
