import { gql } from "apollo-boost";
import { checkValidToken } from "../../utility/auth";
import { EXPERIENCE_SELF_SERVICE_URL } from "../../utility/constants";

export const GET_PRODUCTS_RATE = gql`
  query productsRate($input: RateData) {
    productsRate(input: $input) {
      periods {
        firstPeriod
        dateFrom
        dateTo
        actualPrice
      }
    }
  }
`;

export const GET_UPSELL = gql`
  query upsell($productId: Int!, $currentSubscriptions: [SubscriptionStub]) {
    upSellProposal(
      productId: $productId
      currentSubscriptions: $currentSubscriptions
    ) {
      id
      name
      endDateFriendly
      singleMonth
      price {
        amount
      }
      productInfo {
        title
        shortDescription
        subheader
        imageUrl
      }
    }
  }
`;

export const fetchCustomer = async () => {
  const token = checkValidToken();

  const response = await fetch(EXPERIENCE_SELF_SERVICE_URL + "customer", {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  if (!response.ok) {
    throw new Error(response.json());
  }

  return response.json();
};

export const pollCreditCard = async ({ queryKey }) => {
  const token = checkValidToken();
  const [_, transactionId] = queryKey;

  const response = await fetch(
    EXPERIENCE_SELF_SERVICE_URL + "customer/creditCard/" + transactionId,
    {
      method: "GET",
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );

  if (!response.ok) {
    throw new Error(response.json());
  }

  return response.json();
};

export const getUpSellProducts = async ({ productId }) => {
  const token = checkValidToken();

  const response = await fetch({
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  if (!response.ok) {
    throw new Error(response.json());
  }

  return response.json();
};
