import {
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_PRODUCT_AS_CHILDREN,
  SET_PRODUCT_PRICE,
  SET_PROPOSAL,
  SET_PROPOSAL_LOADING,
  SET_PROPOSAL_CHOICE,
  ACTIVATE_PROPOSAL,
  SKIP_PROPOSAL,
  SET_VOUCHER,
  SET_SUBSCRIPTIONS,
  SET_PRODUCT_LIST
} from "./actions";

const initialState = {
  id: null,
  name: null,
  price: {
    amount: null,
  },
  listPrice: {
    amount: null,
  },
  endDateFriendly: null,
  productInfo: {
    title: null,
    subheader: null,
    shortDescription: null,
    longDescription: null,
    imageUrl: null,
    iconUrl: null,
    iconsUrls: [],
  },
  proposal: null,
  proposalLoading: true,
  skipProposal: false,
  binding: {
    period: null,
    periodFriendly: null,
    endDate: null,
    startDate: null,
  },
  voucher: null,
  subscriptions: null,
  productList: []
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        ...action.product,
      };
    case SET_PRODUCT_ID:
      return {
        ...state,
        id: action.id,
      };
    case SET_PRODUCT_AS_CHILDREN:
      return {
        ...state,
        ...action.childrenProduct,
      };
    case SET_PROPOSAL:
      return {
        ...state,
        proposal: action.proposal,
      };
    case SET_PROPOSAL_CHOICE:
      return {
        ...state,
        proposalChoice: action.product,
      };
    case SET_PROPOSAL_LOADING:
      return {
        ...state,
        proposalLoading: false,
      };
    case SKIP_PROPOSAL:
      return {
        ...state,
        skipProposal: true,
      };
    case ACTIVATE_PROPOSAL:
      return {
        ...state,
        id: state.proposalChoice.id,
        price: state.proposalChoice.price,
        name: state.proposalChoice.name,
        productInfo: state.proposalChoice.productInfo,
        usingProposal: true,
      };
    case SET_PRODUCT_PRICE:
      return {
        ...state,
        price: {
          amount: action.price,
        },
        listPrice: {
          amount: action.listPrice,
        },
      };
    case SET_VOUCHER:
      return {
        ...state,
        voucher: action.voucher,
      };
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.subscriptions,
      };
      case SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.productList,
      };
    default:
      return state;
  }
}
