import { SET_VOUCHER } from "./actions";

const initialState = {
  voucherCode: null,
  active: false,
  discount: 0,
};

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VOUCHER:
      return {
        ...state,
        voucherCode: action.voucherCode,
        active: true,
        discount: action.discount,
      };
    default:
      return state;
  }
}
