import { useLazyQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import { parse } from "query-string";
import React, { useEffect } from "react";
import * as ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../../GlobalStyle";
import {
  setDisplayProposal,
  setFakeOldProductIds,
} from "../../redux/reducers/menu/actions";
import { setProduct, skipProposal } from "../../redux/reducers/product/actions";
import { getProduct } from "../../redux/reducers/product/selectors";
import { getTheme } from "../../redux/reducers/theme/selectors";
import { setPaymentTypeSelectEnabled } from "../../redux/reducers/user/actions";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import ProductImage from "../Shared/ProductImage/ProductImage";
import {
  Button,
  LargeHeader,
  Layout,
  NormalText,
  SmallHeader,
  Spinner,
  SubHeader,
} from "../Shared/Shared";
import { SubHeader2 } from "../Shared/Typography/Header";
import { formatPrice } from "../Shared/utility";
import BundleOption from "./BundleOption";
import Channels from "./Channels";
import Donations from "./Donations";
import ProductPeriods from "./ProductPeriods";
import { SELLABLE_BY_PRODUCT_ID } from "./queries";
import * as ProductStyle from "./Styles";
import { Helmet } from "react-helmet";

const { ActionContainer, CenteredContainer, Break } = Layout;

export default function Product() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });
  const product = useSelector(getProduct);
  const theme = useSelector(getTheme);

  let { productId, endDate, p, oldProducts, paymentType } = parse(
    window.location.search
  );

  // Disable proposal for all users since it doesn't work at the moment with 3Dsecure redirect MULE-1821
  // useEffect(() => {
  //   // proposal enabled for everyone (this was disabled for most users)
  //   dispatch(setDisplayProposal(true));
  // }, [p, dispatch]);

  useEffect(() => {
    // paymentType select enabled for everyone (this was disabled for most users)
    dispatch(setPaymentTypeSelectEnabled());
    dispatch(skipProposal());
  }, [paymentType, dispatch]);

  useEffect(() => {
    localStorage.removeItem("userEmail");
  }, []);

  // Disable proposal for all users since it doesn't work at the moment with 3Dsecure redirect MULE-1821
  // useEffect(() => {
  //   // by setting oldProducts=36459,36459,etc in the query string you can fool the proposal to think you have some specific content registerd
  //   if (oldProducts) {
  //     dispatch(setDisplayProposal(true));
  //     dispatch(setFakeOldProductIds(oldProducts));
  //   }
  // });

  const [sellableProduct, { loading, error }] = useLazyQuery(
    SELLABLE_BY_PRODUCT_ID,
    {
      variables: {
        productId: Number(productId),
      },
      onCompleted: (data) => {
        if (data.sellableByProductId) {
          dispatch(setProduct(data.sellableByProductId));
          localStorage.setItem("product_id", productId);
        } else {
          navigate("/");
        }
      },
    }
  );
  useEffect(() => {
    if (product && product.parameters) {
      window.analytics.page("Product");
      window.analytics.track("Product Viewed", {
        product_id: product.id,
        sku: product.parameters.find((param) => param.name === "productCode")
          .defaultValue,
        category: "1988",
        brand: theme,
        name: product.name,
        price: product.price.amount,
        quantity: 1,
        currency: "ISK",
        value: product.price.amount,
        url: window.location.href,
        image_url: product.productInfo.imageUrl,
      });
    }
  }, [product, theme]);

  useEffect(() => {
    if (productId) {
      sellableProduct();
    }
  }, [productId, sellableProduct]);

  if (error) {
    navigate("/error", {
      state: {
        errorMessage: "Mistókst að sækja upplýsingar um vöru",
      },
    });
  }

  if (loading) {
    return <Spinner />;
  }

  const url = window.location.href;
  const isVodafoneHostname =
    /([a-z]|[A-Z])*.vodafone.is$/g.test(url) ||
    /([a-z]|[A-Z])*-vf.*.itnet.is$/g.test(url);

  if (!loading && product?.id) {
    const { productInfo, price, singleMonth } = product;
    if (!productInfo) {
      navigate("/");
    }
    const {
      title,
      subheader,
      longDescription,
      whereToWatchDescription,
    } = productInfo;
    return (
      <ThemeProvider theme={{ theme: theme }}>
        <GlobalStyle />
        <Helmet defer={false}>
          {/* <!-- Primary Meta Tags --> */}
          <title>
            {title} {isVodafoneHostname ? "| Vodafone" : "| Stöð 2"}
          </title>
          <meta name="title" content={title} />
          <meta name="description" content={subheader} />
          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:title" content={title} />
          <meta property="og:description" content={subheader} />
          {/* <!-- Twitter --> */}
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={subheader} />
        </Helmet>
        <CenteredContainer>
          <ProductStyle.Container>
            <ActionContainer>
              {isTabletOrMobile && <ProductImage />}
              <LargeHeader>{title}</LargeHeader>
              <SubHeader>{subheader}</SubHeader>
              <NormalText>
                <ReactMarkdown source={longDescription} escapeHtml={false} />
              </NormalText>
              <Channels useHeader={true} />
              {/* BundleOption not in use for any voucher atm (f.x. NFL)
              But keeping code for later use (HUG-494) */}
              {/* <BundleOption /> */}
              {/* Donations for vinnum saman from contentful not in use for this season */}
              {/* <Donations /> */}
              <SmallHeader>Byrjaðu strax að horfa</SmallHeader>
              <ProductStyle.WhereToWatchContainer>
                <NormalText>
                  <ReactMarkdown
                    source={
                      whereToWatchDescription ??
                      `Þegar þú kaupir áskrift að ${product.name} getur þú strax byrjað
                að horfa í Vefsjónvarpi Stöðvar 2, í Stöð 2 appinu í
                snjalltækjum, AppleTV eða með myndlykli.`
                    }
                  />
                </NormalText>
              </ProductStyle.WhereToWatchContainer>
              <Break size={"30px"} />
              {/* <ProductPeriods endDate={endDate} /> */}
              <SubHeader2>Kaupa</SubHeader2>
              {price && price.amount && (
                <ProductStyle.PriceContainer>
                  <ProductStyle.Price theme={theme}>
                    {formatPrice(price.amount)}
                  </ProductStyle.Price>
                  <NormalText>kr. {singleMonth ? "" : "á mánuði"}</NormalText>
                </ProductStyle.PriceContainer>
              )}
              <Break size={"20px"} />
              {!isTabletOrMobile && (
                <Button active onClick={() => navigate("/login")}>
                  ÁFRAM
                </Button>
              )}
            </ActionContainer>
            {!isTabletOrMobile && <ProductImage />}
          </ProductStyle.Container>
          {isTabletOrMobile && (
            <ProductStyle.ContinueMobile>
              <Button
                active
                width={"80"}
                marginRight={"0"}
                onClick={() => navigate("/login")}
              >
                ÁFRAM
              </Button>
            </ProductStyle.ContinueMobile>
          )}
        </CenteredContainer>
      </ThemeProvider>
    );
  }

  if (!productId) {
    navigate("/");
  }

  return <></>;
}
