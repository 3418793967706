import styled, { keyframes } from "styled-components";
import {
  LAYOUT_MAX_WIDTH,
  MOBILE_BREAKPOINT,
  MOBILE_SMALL_BREAKPOINT,
} from "../../utility/constants";
import { color, alert } from "../Shared/Color/colors";

const popIn = keyframes`
 from { 
  opacity: 0;
  transform: translateY(50%);
}
 to { 
  opacity: 1;
  transform: translateY(0%);
  }
`;

export const BackgroundTeal = styled.div`
  z-index: -1;
  display: inline-block;
  background: ${color.white};
  background: linear-gradient(
    270deg,
    ${color.transparent} 30%,
    ${color.white} 100%
  );
  position: absolute;
  width: 100%;
  height: 110%;
  margin-top: -50px;

  left: 0;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    background: linear-gradient(
      270deg,
      ${color.transparent} 40%,
      ${color.white} 100%
    );
    margin-top: -25px;
    left: -20px;
  }
`;

export const SummaryContainerBox = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  max-width: ${LAYOUT_MAX_WIDTH};
  padding: 50px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;
  }

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    max-width: none;
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ProductContainerBox = styled(Container)`
  width: 50%;
  max-width: 576px;
  gap: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    max-width: none;
  }
`;

export const PaymentContainerBox = styled(Container)`
  width: 416px;
  gap: 24px;
  padding: 0px 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    padding: 0px;
  }
`;

export const StickyBox = styled(Container)`
  position: sticky;
  top: 50px;
  gap: 24px;
`;

export const TitleContainerBox = styled(Container)`
  gap: 8px;
  max-width: 400px;
`;

export const KartContainerBox = styled(Container)`
  padding: 24px;
  gap: 24px;
  overflow: hidden;
  margin-bottom: 18px;
  height: ${(props) => (props.conflict ? 168 : 144) + (props.size - 1) * 168}px;

  border: 1px solid ${color.tealPlus60};
  border-radius: 4px;

  transition: all 0.5s ease;

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    height: ${(props) =>
      (props.conflict ? 424 : 400) + (props.size - 1) * 424}px;
  }
`;

export const WarningIcon = styled(Container)`
  display: none;
  width: 16px;
  height: 16px;
  position: absolute;

  top: 10px;
  right: 10px;
`;

export const WarningLabel = styled(Container)`
  width: 16px;
  height: 16px;
`;

export const WarningContainer = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 8px;
`;

export const ProductCardContainer = styled(Container)`
  gap: 8px;
`;

export const ProductCard = styled(Container)`
  flex-direction: row;

  gap: 16px;
  width: 100%;
  max-height: 144px;
  background-color: ${color.white};
  border-radius: 4px;

  animation: 0.5s ${popIn} ease;

  :first-child {
    ${WarningIcon} {
      display: ${(props) => (props.hasConflict ? "block" : "none")};
    }
    outline: ${(props) => (props.hasConflict ? 3 : 0)}px solid ${alert.warning};
  }

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-start;
    max-height: 400px;
    height: 400px;
  }
`;

export const ProductImage = styled(Container)`
  width: 192px;
  height: 144px;
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;

  img {
    height: 100%;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    width: 100%;
    height: 270px;
    border-radius: 4px 4px 0px 0px;

    img {
      object-fit: cover;
      width: 100%;
    }
  }
`;

export const ProductInfo = styled(Container)`
  max-height: 144px;
  justify-content: space-between;
  padding: 16px 0px;

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    max-height: 130px;
    padding: 0px 0px 16px 16px;
    gap: 12px;
  }
`;

export const ProductInfoText = styled(Container)`
  max-height: 105px;
  max-width: 315px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    max-height: 98px;
    height: 98px;
  }
`;

export const ProductListContainerBox = styled(Container)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 4%;

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    flex-direction: column;
    gap: 2%;
  }
`;

export const UpSellCard = styled(Container)`
  width: 48%;
  max-width: 276px;
  box-shadow: 0px 0px 8px #00000033;
  background-color: ${color.white};
  border-radius: 8px;
  margin-bottom: 18px;
  z-index: 1;
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 48%;
    max-width: none;
    margin-bottom: 24px;
  }

  @media (max-width: ${MOBILE_SMALL_BREAKPOINT}) {
    width: 100%;
    margin-bottom: 18px;
  }
`;

export const UpSellOverlay = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);

  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  opacity: ${(props) => (props.show ? 0.5 : 0)};

  transition: all 0.5s ease;
`;

export const UpSellLabel = styled(Container)`
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 8px;

  align-items: center;
  padding: 8px;
  background-color: ${color.white};
  border-radius: 4px;

  color: ${color.passionPurple};
  font-size: 12px;
  font-weight: 900;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

export const UpSellImage = styled(Container)`
  width: 100%;
  height: 207px;
  border-radius: 4px;
  overflow: hidden;

  img {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    height: 270px;

    img {
      object-fit: cover;
      width: 100%;
    }
  }
`;

export const UpSellInfo = styled(Container)`
  padding: 16px;
  gap: 24px;
`;

export const UpSellText = styled(Container)`
  padding: 0px;
  gap: 8px;
`;

export const UpSellFooter = styled(Container)`
  padding: 0px 16px 16px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: auto;
`;

export const UpSellPrice = styled(Container)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const UpSellButton = styled.button`
  display: flex;
  border: none;
  flex-direction: row;
  align-items: flex-start;
  z-index: 3;
  gap: 7px;
  padding: 8px;
  background-color: ${color.passionPurple};
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: ${(props) => (props.added ? 32 : 90)}px;
  width: 100%;
  height: 32px;

  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${color.white};

  :hover {
    outline: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  transition: all 0.5s ease;
`;

export const TermsContainerBox = styled(Container)`
  padding: 16px;
  background-color: ${color.antiFlashWhite};
  border-radius: 4px;
`;

export const ListContainerBox = styled(Container)`
  gap: 24px;
`;

export const ButtonContainerBox = styled(Container)``;

export const StickyButton = styled(Container)`
  display: none;
  z-index: 4;
  position: sticky;
  bottom: 25px;
  width: 50%;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: ${(props) => (props.visible ? "none" : "block")};
  }
`;

export const SumContainerBox = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PriceContainer = styled.div`
  text-align: end;
`;

const Text = styled.p`
  margin: 0;
`;

export const LargerTitle = styled(Text)`
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  color: ${color.japaneseIndigo};
`;

export const LargeTitle = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  color: ${color.japaneseIndigo};
`;

export const SmallTitle = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;

  color: ${color.darkCharcoal};
`;

export const LargeLabel = styled(Text)`
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: ${color.davyGrey};
`;

export const DescriptionText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${color.darkCharcoal};
`;

export const WarningText = styled(Container)`
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${color.darkGrey};
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
`;

export const HelpText = styled.span`
  visibility: hidden;
  background-color: ${color.white};
  width: 182px;
  text-align: center;
  border-radius: 4px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  border: 1px solid #a6babc;

  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${color.tealPlus60} transparent transparent transparent;
  }
`;

export const HelpIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;

  :hover {
    ${HelpText} {
      visibility: visible;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
`;
