const prefix = "product/";
const SET_PRODUCT = `${prefix}SET_PRODUCT`;
const SET_PRODUCT_ID = `${prefix}SET_PRODUCT_ID`;
const SET_PRODUCT_AS_CHILDREN = `${prefix}SET_PRODUCT_AS_CHILDREN`;
const SET_PRODUCT_PRICE = `${prefix}SET_PRODUCT_PRICE`;
const SET_PROPOSAL = `${prefix}SET_PROPOSAL`;
const SET_PROPOSAL_LOADING = `${prefix}SET_PROPOSAL_LOADING`;
const SET_PROPOSAL_CHOICE = `${prefix}SET_PROPOSAL_CHOICE`;
const ACTIVATE_PROPOSAL = `${prefix}ACTIVATE_PROPOSAL`;
const SKIP_PROPOSAL = `${prefix}SKIP_PROPOSAL`;
const SET_VOUCHER = `${prefix}SET_VOUCHER`;
const SET_SUBSCRIPTIONS = `${prefix}SET_SUBSCRIPTIONS`;
const SET_PRODUCT_LIST = `${prefix}SET_PRODUCT_LIST`;

const setProduct = (product) => ({
  type: SET_PRODUCT,
  product,
});

const setProductId = (id) => ({
  type: SET_PRODUCT_ID,
  id,
});

const setProductAsChildren = (childrenProduct) => ({
  type: SET_PRODUCT_AS_CHILDREN,
  childrenProduct,
});
const setProductPrice = (price, listPrice) => ({
  type: SET_PRODUCT_PRICE,
  price,
  listPrice,
});
const setProposal = (proposal) => ({
  type: SET_PROPOSAL,
  proposal,
});
const setProposalLoading = (proposalLoading) => ({
  type: SET_PROPOSAL_LOADING,
  proposalLoading,
});
const setProposalChoice = (product) => ({
  type: SET_PROPOSAL_CHOICE,
  product,
});
const activateProposal = () => ({
  type: ACTIVATE_PROPOSAL,
});
const skipProposal = () => ({
  type: SKIP_PROPOSAL,
});

const setVoucher = (voucher) => ({
  type: SET_VOUCHER,
  voucher,
});

const setSubscriptions = (subscriptions) => ({
  type: SET_SUBSCRIPTIONS,
  subscriptions,
});

const setProductList = (productList) => ({
  type: SET_PRODUCT_LIST,
  productList,
});

export {
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_PRODUCT_AS_CHILDREN,
  SET_PRODUCT_PRICE,
  SET_PROPOSAL,
  SET_PROPOSAL_LOADING,
  SET_PROPOSAL_CHOICE,
  ACTIVATE_PROPOSAL,
  SKIP_PROPOSAL,
  SET_VOUCHER,
  SET_SUBSCRIPTIONS,
  SET_PRODUCT_LIST,
  setProduct,
  setProductId,
  setProductAsChildren,
  setProductPrice,
  setProposal,
  setProposalLoading,
  setProposalChoice,
  activateProposal,
  skipProposal,
  setVoucher,
  setSubscriptions,
  setProductList
};
