import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../utility/constants";

const EID = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const PhoneCall = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: row;
  }
`;

const ButtonRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonLabel = styled.span`
  margin-left: 5px;
`;


export { EID, Form, PhoneCall, ButtonRow, ButtonLabel };
