import styled from "styled-components";

const ProcessInfo = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;

export { ProcessInfo };
