import React from "react";
import { LinkStyle } from "./Styles";

export default function LinkButton({
  to,
  label,
  newTab = true,
  fontSize = "15px",
}) {
  const handleClick = () => {
    if (to && newTab) {
      window.open(to, "_blank");
    } else if (to) {
      window.open(to);
    }
  };
  return (
    <LinkStyle
      fontSize={fontSize}
      onClick={handleClick}
      decoration={to !== undefined ? "underline" : "none"}
    >
      {label}
    </LinkStyle>
  );
}
