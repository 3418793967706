import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";

import { getUser } from "../../../redux/reducers/user/selectors";
import { Input } from "../../Shared/Input/Input";
import InputError from "../../Shared/Input/InputError";
import { Layout, NormalText } from "../../Shared/Shared";
import { Form, Container, InnerContainer } from "./ViaplayEmailForm.styles";
import { getMenu } from "../../../redux/reducers/menu/selectors";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import { setUserEmail } from "../../../redux/reducers/user/actions";

const { Break } = Layout;

export default function ViaplayEmailForm() {
  const user = useSelector(getUser);
  const theme = useSelector(getTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, errors, triggerValidation, watch } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    async function init() {
      await triggerValidation();
    }
    init();
  }, [triggerValidation]);

  if (!user.authenticated) {
    navigate("/unauthorized");
    return <></>;
  }

  const handleEmailChanged = () => {
    const { email } = watch();
    dispatch(setUserEmail(email));
  };

  return (
    <Container theme={theme}>
      <InnerContainer theme={theme}>
        <NormalText>
          Til að geta veitt sjónvarpsþjónustu í gegnum Viaplay þurfum við
          eftirfarandi notendaupplýsingar,&nbsp;
        </NormalText>
        <Break size={"30px"} />
        <Form>
          <Input
            onChange={handleEmailChanged}
            name="email"
            type="email"
            autoFocus
            defaultValue={user.email}
            ref={register({
              required: true,
              validate: (value) => isEmail(value),
            })}
          />
          <InputError
            message={
              errors?.email?.type === "validate"
                ? "Netfang ekki á réttu formi"
                : ""
            }
          />
          <Break />
        </Form>
      </InnerContainer>
    </Container>
  );
}
