import React from "react";
import styled from "styled-components";
import { color } from "../Color/colors";

const RadioButtonContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const StyledRadioButton = styled.div`
  display: inline-block;
  width: ${(props) => (props.checked ? `24px` : `32px`)};
  height: ${(props) => (props.checked ? `24px` : `32px`)};
  background: ${(props) =>
    props.checked ? `${color.greenish}` : `${color.white}`};
  border: ${(props) =>
    props.checked
      ? `5px solid ${color.white}`
      : `1px solid ${color.lightGrey}`};
  border-radius: 28px;
  cursor: pointer;
  ${Icon} {
    transition: opacity 150ms ease-in-out;
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
`;

const RadioButtonComponent = ({ className, checked, ...props }) => (
  <RadioButtonContainer className={className}>
    <StyledRadioButton checked={checked}></StyledRadioButton>
  </RadioButtonContainer>
);

export function RadioButton({ checked }) {
  return <RadioButtonComponent checked={checked} />;
}
