import React from "react";
import { Button } from "../Shared/Shared";
import {
  LinkBoxHeader,
  LinkBoxItem,
  LinkBoxText,
  LinkBoxTextContainer,
  LinkBoxImage,
} from "./Styles";

export default function LinkBox({
  imageUrl,
  url,
  header,
  body,
  buttonLabel,
  showButton = true,
}) {
  return (
    <LinkBoxItem>
      <LinkBoxImage alt={header} src={imageUrl} />
      <LinkBoxTextContainer>
        <LinkBoxHeader>{header}</LinkBoxHeader>
        <LinkBoxText>{body}</LinkBoxText>
        {showButton && (
          <Button
            active
            width="50"
            marginRight={"0px"}
            onClick={() => window.open(url, "_blank")}
          >
            {buttonLabel}
          </Button>
        )}
      </LinkBoxTextContainer>
    </LinkBoxItem>
  );
}
