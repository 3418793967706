import {
  TOGGLE_MENU,
  SHOW_MENU,
  HIDE_MENU,
  SET_DISPLAY_PROPOSAL,
  SET_FAKE_OLD_PRODUCT_IDS
} from "./actions";

const initialState = {
  show: false,
  displayProposal: false,
  fakedOldProductIds: undefined
};

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        show: !state.show,
      };
    case SHOW_MENU:
      return {
        ...state,
        show: true,
      };
    case HIDE_MENU:
      return {
        ...state,
        show: false,
      };
    case SET_DISPLAY_PROPOSAL:
      return {
        ...state,
        displayProposal: action.value
      };
    case SET_FAKE_OLD_PRODUCT_IDS:
      return {
        ...state,
        fakedOldProductIds: action.productIds
      };
    default:
      return state;
  }
}