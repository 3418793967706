import rg4js from 'raygun4js';
import { EXPERIENCE_PUBLIC_URL } from './constants';

const fetch = require('node-fetch');

function checkStatusCode(res) {
  if (res.ok) {
    return res;
  } else {
    rg4js("send", { error: `Error: ${res.statusText}` });
  }
}

export function convertSsnToGuid(nationalId) {
  return fetch(EXPERIENCE_PUBLIC_URL + nationalId + '/guid', {method: 'GET'})
    .then(checkStatusCode)
    .then(res => {
      return res.json();
    }).catch(function(err) {
      rg4js("send", { error: `GUID conversion error: ${err.message}` });
    });
}