import rg4js from "raygun4js";
import React from "react";
import error from "../../images/stod2_500_error.png";
import LinkButton from "../Shared/Link/Link";
import { Container, ErrorDescription, ErrorHeader, ErrorImage, ErrorMessage } from "./Styles";
import {
  STOD2_REDIRECT,
  VODAFONE_REDIRECT,
  THEME,
} from "../../utility/constants";
import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/theme/selectors";
import { NormalText } from "../Shared/Shared";
import { Break } from "../Shared/Layout/Layout";

export default function ErrorPage(props) {
  const theme = useSelector(getTheme);
  const errorMessage = props.location?.state?.errorMessage;
  rg4js("send", { error: errorMessage ? errorMessage : "Landed on Error page" });

  window.analytics.page("Error");

  return theme !== THEME.VODAFONE ? (
    <Container>
      <ErrorHeader>Það kom villa upp</ErrorHeader>
      <ErrorImage>
        <img src={error} alt="Villa kom upp" />
      </ErrorImage>
      { errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : ''}
      <ErrorDescription>
        Vinsamlegast reyndu aftur síðar eða hafðu samband við þjónustuver
        Vodafone.
      </ErrorDescription>
      <LinkButton to={STOD2_REDIRECT} label="Fara á kaupsíðu stöð2.is" />
    </Container>
  ) : (
    <Container>
      <ErrorHeader>Það kom villa upp</ErrorHeader>
      { errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : ''}
      <NormalText style={{maxWidth: "600px"}}>
        Óvænt villa kom upp. Ef þú lendir áfram í vandræðum getur þú haft
        samband við okkur í gegnum Netspjallið eða þjónustuver Vodafone í síma
        1414.
      </NormalText>
      <Break/>
      <LinkButton to={VODAFONE_REDIRECT} label="Fara á vodafone.is" />
    </Container>
  );
}
