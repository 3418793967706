import {
  gql
} from "apollo-boost";

export const GET_PRODUCTS_RATE_MONTHLY = gql `
query productsRateMonthly($productId: Int!){
  productsRateMonthly(input: {products: {productId: $productId}}) {
    listPrice
    actualPrice
  }
}
`;