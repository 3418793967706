import React from "react";
import { useSelector } from "react-redux";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import { getProduct } from "../../../redux/reducers/product/selectors";
import { getVoucherCode } from "../../../redux/reducers/voucher/selectors";
import { getUser } from "../../../redux/reducers/user/selectors";
import { TextContainer, FormContainer, RowItem } from "./Styles";
import PaymentTypeSelect from "../PaymentTypeSelect/PaymentTypeSelect";
import CreditCardForm from "../CreditCardForm/CreditCardForm";
import { LabelText } from "../../Shared/Shared";

export default function PaymentForm(props) {
  const theme = useSelector(getTheme);
  const product = useSelector(getProduct);
  const voucherCode = useSelector(getVoucherCode);
  const user = useSelector(getUser);

  const callbackCardInfoToParent = (cardInfo) => {
    props.callback(cardInfo);
  };

  return (
    <>
      <FormContainer theme={theme}>
        <RowItem>
          <PaymentTypeSelect
            callback={(cardInfo) => callbackCardInfoToParent(cardInfo)}
          />
        </RowItem>
        {user.selectedPaymentType === "ELECTRONIC" && !voucherCode && (
          <TextContainer>
            <LabelText margin="20px 0 0 0">
              Útskriftargjald 189 kr. bætist við reikning í heimabanka.
            </LabelText>
          </TextContainer>
        )}
      </FormContainer>
    </>
  );
}
