import { useLazyQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProductPrice } from "../../../redux/reducers/product/actions";
import {
  getProduct,
  isProductPPV,
  isGiftCardAccepted,
} from "../../../redux/reducers/product/selectors";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import { setVoucher } from "../../../redux/reducers/voucher/actions";
import { isVoucherActive } from "../../../redux/reducers/voucher/selectors";
import { color } from "../../Shared/Color/colors";
import { Input } from "../../Shared/Input/Input";
import InputError from "../../Shared/Input/InputError";
import { InputErrorWrap } from "../../Shared/Input/Styles";
import { NewButton, Spinner } from "../../Shared/Shared";
import { getFirstRatePeriod, getGraphQLErrorCodes } from "../../Shared/utility";
import { GET_PRODUCTS_RATE_VOUCHER } from "./queries";
import {
  Container,
  InnerContainer,
  VoucherButtonLabel,
  VoucherForm,
} from "./Styles";

export default function Voucher() {
  const dispatch = useDispatch();
  const product = useSelector(getProduct);
  const [errorMessage, setErrorMessage] = useState(null);
  const [voucherCode, setVoucherCode] = useState(null);
  const ppv = useSelector(isProductPPV);
  const giftCard = useSelector(isGiftCardAccepted);
  const voucherActive = useSelector(isVoucherActive);
  const theme = useSelector(getTheme);

  function errorHandler(error) {
    const errorCodes = getGraphQLErrorCodes(error);
    setErrorMessage(
      // eslint-disable-next-line no-nested-ternary
      errorCodes.includes("BAD_USER_INPUT")
        ? "Kóði fannst ekki fyrir þessa vöru."
        : errorCodes.includes("FORBIDDEN")
        ? "Of margar tilraunir, reyndu aftur síðar"
        : "Villa kom upp. Vinsamlegast reyndu aftur síðar."
    );
  }

  const [activateVoucher, { loading }] = useLazyQuery(
    GET_PRODUCTS_RATE_VOUCHER,
    {
      onCompleted: (data) => {
        if (data?.productsRate) {
          const firstPeriod = getFirstRatePeriod(data);
          if (firstPeriod) {
            const { actualPrice, listPrice } = firstPeriod;
            dispatch(setProductPrice(actualPrice, listPrice));
            dispatch(setVoucher(voucherCode));
            setErrorMessage(null);
          } else {
            setErrorMessage("Villa kom upp. Vinsamlegast reyndu aftur síðar.");
          }
        }
      },
      onError: (error) => errorHandler(error),
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!voucherCode && voucherCode === "") {
      setErrorMessage(null);
    }
  }, [voucherCode]);

  return ppv || giftCard ? (
    <Container theme={theme}>
      <InnerContainer theme={theme}>
        <VoucherForm>
          <Input
            name="voucher"
            placeholder="Setja inn afsláttarkóða"
            autoComplete="off"
            type="text"
            marginRight="15px"
            max-width="100"
            onChange={(e) => setVoucherCode(e.target.value)}
          />
          <NewButton
            responsive
            active={!loading && !voucherActive}
            onClick={(e) => {
              e.preventDefault();
              if (!loading && !voucherActive) {
                activateVoucher({
                  variables: {
                    productId: Number(product.id),
                    voucherCode: voucherCode.trim(),
                  },
                });
              }
            }}
          >
            <VoucherButtonLabel>
              {loading && <Spinner color={color.white} size={18} />}
              &nbsp;Virkja
            </VoucherButtonLabel>
          </NewButton>
        </VoucherForm>
        {errorMessage && (
          <InputErrorWrap>
            <InputError message={errorMessage} />
          </InputErrorWrap>
        )}
      </InnerContainer>
    </Container>
  ) : null;
}
