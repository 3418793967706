import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../redux/reducers/product/selectors";
import { getUser } from "../../redux/reducers/user/selectors";
import {
  Button,
  LargeHeader,
  Layout,
  NormalText,
  Spinner,
} from "../Shared/Shared";
import { formatPrice, getProductPackageText } from "../Shared/utility";
import { GET_SUBSCRIPTIONS } from "./queries";
import {
  Grid,
  Container,
  ProposalBox,
  ProposalBoxTop,
  ProposalBoxSubHeader,
  ProposalBoxLabel,
  ProposalBoxPackage,
  ProposalBoxBottom,
  ProposalBoxMiddle,
  ProposalSummaryPrice,
  ProposalCurrentPrice,
  ProposalSummaryPriceWrapper,
  ProposalRadioWrapper,
  RadioContainer,
} from "./Styles";
import { RadioButton } from "../Shared/RadioButton/RadioButton";
import {
  setProposalChoice,
  activateProposal,
  setSubscriptions
} from "../../redux/reducers/product/actions";
import Channels from "../Product/Channels";
import ProductProposal from "./ProductProposal";
import { getTheme } from "../../redux/reducers/theme/selectors";
import { Break } from "../Shared/Layout/Layout";

export default function Proposal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const product = useSelector(getProduct);
  const user = useSelector(getUser);
  const theme = useSelector(getTheme);

  const { data, loading } = useQuery(GET_SUBSCRIPTIONS, {
    onError: () => navigate("/error"),
    onCompleted: () => dispatch(setSubscriptions(data.subscriptions)),
  });
  function getTotalCurrentPrice() {
    let totalCurrentPrice = product.price.amount;
    for (const prod of product.proposal.currentProducts) {
      totalCurrentPrice += prod.price.amount;
    }
    return totalCurrentPrice;
  }
  function onSubmit() {
    if (product.proposalChoice) {
      if (product.proposalChoice.id === product.proposal.product.id) {
        dispatch(activateProposal());
      }
      navigate("/order/summary");
    }
  }

  if (!user.authenticated) {
    navigate("/unauthorized");
    return <></>;
  }

  if (loading && product.proposalLoading) {
    return <Spinner />;
  }

  if (data?.subscriptions?.length > 0) {
    return (
      <Layout.CenteredContainer>
        <ProductProposal subscriptions={data.subscriptions} />
        {product.proposalLoading && <Spinner />}
        {!product.proposalLoading && product.proposal.product && (
          <Container>
            <LargeHeader>Rétti pakkinn</LargeHeader>
            <ProposalBoxSubHeader>
              Ertu að velja rétta pakkann?
            </ProposalBoxSubHeader>
            <ProposalBoxSubHeader>Veldu pakka</ProposalBoxSubHeader>

            <Grid columns={2}>
              <ProposalBox theme={theme}>
                <ProposalBoxTop
                  className={
                    product.proposalChoice?.id === product.id ? "active" : ""
                  }
                  onClick={() =>
                    dispatch(setProposalChoice({ id: product.id }))
                  }
                >
                  <RadioContainer>
                    <ProposalRadioWrapper>
                      <RadioButton
                        checked={product.proposalChoice?.id === product.id}
                      />
                    </ProposalRadioWrapper>
                  </RadioContainer>
                  <ProposalBoxSubHeader>
                    Kaupa {product.name}
                  </ProposalBoxSubHeader>
                  Kaupa {product.name} og halda áfram áskrift að{" "}
                  {getProductPackageText(product.proposal.currentProducts)}.
                </ProposalBoxTop>

                <ProposalBoxMiddle>
                  <ProposalBoxLabel>Þú ert að kaupa</ProposalBoxLabel>
                  <Grid columns={2} style={{ marginTop: "0px" }}>
                    <ProposalBoxPackage theme={theme}>
                      {product.name}
                    </ProposalBoxPackage>
                    <ProposalCurrentPrice>
                      {formatPrice(product.price.amount)}{" "}
                      <NormalText style={{ display: "contents" }}>
                        kr./mán
                      </NormalText>
                    </ProposalCurrentPrice>
                  </Grid>
                  <ProposalBoxLabel style={{ marginTop: "25px" }}>
                    Þú ert þegar með
                  </ProposalBoxLabel>
                  <Grid columns={2} style={{ marginTop: "0px" }}>
                    {product.proposal.currentProducts.map((prod, key) => {
                      return (
                        <Fragment key={key}>
                          <ProposalBoxPackage theme={theme}>
                            {prod.name}
                          </ProposalBoxPackage>
                          <ProposalCurrentPrice>
                            {formatPrice(prod.price.amount)}{" "}
                            <NormalText style={{ display: "contents" }}>
                              kr./mán
                            </NormalText>
                          </ProposalCurrentPrice>
                        </Fragment>
                      );
                    })}
                  </Grid>
                  {product?.proposal?.currentSelection?.productInfo?.iconsUrls
                    ?.length > 0 && (
                    <ProposalBoxLabel style={{ marginTop: "25px" }}>
                      Innifaldar stöðvar og efnisveitur
                    </ProposalBoxLabel>
                  )}
                  <Channels products={product.proposal.currentSelection} />
                </ProposalBoxMiddle>

                <ProposalBoxBottom>
                  <ProposalSummaryPriceWrapper>
                    Samtals{" "}
                    <ProposalSummaryPrice theme={theme}>
                      {formatPrice(getTotalCurrentPrice())}{" "}
                    </ProposalSummaryPrice>
                    <NormalText style={{ display: "contents" }}>
                      kr./mán
                    </NormalText>
                  </ProposalSummaryPriceWrapper>
                </ProposalBoxBottom>
              </ProposalBox>
              <ProposalBox theme={theme}>
                <ProposalBoxTop
                  className={
                    product.proposalChoice?.id === product.proposal.product.id
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    dispatch(setProposalChoice(product.proposal.product))
                  }
                >
                  <RadioContainer>
                    <ProposalRadioWrapper>
                      <RadioButton
                        checked={
                          product.proposalChoice?.id ===
                          product.proposal.product.id
                        }
                      />
                    </ProposalRadioWrapper>
                  </RadioContainer>
                  <ProposalBoxSubHeader>
                    Breyta í {product.proposal.product.name}
                  </ProposalBoxSubHeader>
                  {product.proposal.product.price.amount <
                  getTotalCurrentPrice() ? (
                    <span>
                      Ódýrari kostur sem inniheldur allar þínar núverandi
                      áskriftir og meira til.
                    </span>
                  ) : (
                    <span>
                      {product.proposal.product.name} inniheldur{" "}
                      {product.proposal.product.channels.length -
                        product.proposal.wantedChannels.length}{" "}
                      stöðvar að auki sem þú hefur mögulega áhuga á.
                    </span>
                  )}
                </ProposalBoxTop>

                <ProposalBoxMiddle>
                  <ProposalBoxLabel>Þú ert að kaupa</ProposalBoxLabel>
                  <Grid columns={2} style={{ marginTop: "0px" }}>
                    <ProposalBoxPackage theme={theme}>
                      {product.proposal.product.name}
                    </ProposalBoxPackage>
                    <ProposalCurrentPrice>
                      {formatPrice(product.proposal.product.price.amount)}{" "}
                      <NormalText style={{ display: "contents" }}>
                        kr./mán
                      </NormalText>
                    </ProposalCurrentPrice>
                    <div>
                      {product.proposal.product.name} inniheldur{" "}
                      {getProductPackageText(product.proposal.currentProducts)}
                    </div>
                  </Grid>
                  {product?.proposal?.product?.productInfo?.iconsUrls?.length >
                    0 && (
                    <ProposalBoxLabel style={{ marginTop: "25px" }}>
                      Innifaldar stöðvar og efnisveitur
                    </ProposalBoxLabel>
                  )}
                  <Break size={"10px"} />
                  <Channels products={[product.proposal.product]} />
                </ProposalBoxMiddle>

                <ProposalBoxBottom>
                  <ProposalSummaryPriceWrapper>
                    Samtals{" "}
                    <ProposalSummaryPrice theme={theme}>
                      {formatPrice(product.proposal.product.price.amount)}{" "}
                    </ProposalSummaryPrice>
                    <NormalText style={{ display: "contents" }}>
                      kr./mán
                    </NormalText>
                  </ProposalSummaryPriceWrapper>
                </ProposalBoxBottom>
              </ProposalBox>
            </Grid>
            <Button
              style={{ width: "215px", marginTop: "20px" }}
              active={!product.proposalLoading && product.proposalChoice}
              onClick={() => onSubmit()}
            >
              ÁFRAM
            </Button>
          </Container>
        )}
      </Layout.CenteredContainer>
    );
  } else {
    navigate("/order/summary");
    return <></>;
  }
}
