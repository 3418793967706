import { useNavigate } from "@reach/router";
import { useQuery } from "react-query";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBillingId, setPaymentTypeConfirmed, setSelectedPaymentType, setUser, setPaymentTypeSelectEnabled } from "../../../redux/reducers/user/actions";
import { PAYMENT_TYPES } from "../../../utility/constants";
import { fetchCustomer } from "../queries";
import { getUser } from "../../../redux/reducers/user/selectors";

export default function PaymentType(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const { transactionId, cardRegistered } = props;

  const updateCustomerInfo = (customer) => {
    dispatch(setUser({
      contract: customer.contract,
      maskedCreditCard: customer.creditCard?.maskedCreditCard,
      billingId: customer.serviceSettings?.tv?.primaryContract,
      currentPaymentType: customer.serviceSettings?.tv?.paymentMethod,
      cardType: customer.creditCard?.type,
      email: customer.email,
      fullName: customer.name,
      nationalID: customer.nationalId,
      phonenumber: customer.phoneNumber,
      serviceSettings: customer.serviceSettings,
      paymentTypeSelectEnabled: true,
      paymentTypeLoaded: true
    }));

    let selectedPaymentType = customer.creditCard?.maskedCreditCard 
      ? PAYMENT_TYPES.CREDITCARD 
      : PAYMENT_TYPES.NEW_CARD

    dispatch(setSelectedPaymentType(selectedPaymentType));

    if (selectedPaymentType === 'CREDITCARD') {
      dispatch(setPaymentTypeConfirmed(true));
    }
  }

  useQuery(['customer'], fetchCustomer, {
    enabled: !transactionId || cardRegistered,
    onSuccess: (customer) => {
      updateCustomerInfo(customer);
    },
    onError: (error) => {
      navigate("/error", { 
        state: { 
          errorMessage: error.message
        }
      });
    }
  });
  
  return null;
}
