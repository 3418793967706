import React from "react";
import styled from "styled-components";
import { color } from "../Color/colors";
import { THEME } from "../../../utility/constants";

const StyledWideRadioButton = styled.div`
  position: relative;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "initial")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "initial")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "initial")};
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight : "initial"};
  color: ${color.teal};
  border: ${(props) =>
    props.checked
      ? `1px solid ${color.teal}`
      : `1px solid ${color.tealPlus60}`};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "5px"};
  cursor: pointer;
  text-align: center;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-line;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  pointer-events: ${(props) => (props.checked ? "none" : "initial")};
  background: ${(props) =>
    props.checked
      ? props.theme === THEME.VODAFONE
        ? color.vodaRed
        : color.white
      : color.white};
`;

const WideRadioButtonComponent = ({ checked, ...props }) => (
  <StyledWideRadioButton checked={checked} {...props} />
);

// eslint-disable-next-line import/prefer-default-export
export function WideRadioButton({ callback, selected, ...props }) {
  return (
    <WideRadioButtonComponent
      onClick={() => {
        callback(!selected);
      }}
      checked={selected}
      {...props}
    />
  );
}
