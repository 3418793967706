import { gql } from "apollo-boost";

export const GET_PRODUCT_CONFLICTS = gql`
  query subscriptions($otherProductId: Int!) {
    subscriptions(typeOf: ["content"], getTerminated: false, includeFamily: true) {
      product {
        id
        name
      }
      typeOf
      productConflict(otherProductId: $otherProductId) {
        productId
        otherProductId
        sameChannels
      }
    }
  }
`;
