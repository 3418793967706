import styled from "styled-components";
import { LargeHeader } from "../Shared/Shared";
import { MOBILE_BREAKPOINT } from "../../utility/constants";

const UserHeader = styled(LargeHeader)`
  font-size: 55px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 33px;
  }
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export { Form, UserHeader };
