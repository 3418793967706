const prefix = "theme/";
const SET_THEME = `${prefix}SET_THEME`;

const setTheme = (theme) => ({
  type: SET_THEME,
  theme,
});

export {
  SET_THEME,
  setTheme
};