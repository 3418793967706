const prefix = "toggle/";
const SET_TOGGLE = `${prefix}TOGGLE`;

const setToggle = (toggle) => ({
  type: SET_TOGGLE,
  toggle,
});

export {
  SET_TOGGLE,
  setToggle
};
