import React from "react";
import error from "../../images/stod2_401_error.png";
import rg4js from "raygun4js";
import LinkButton from "../Shared/Link/Link";
import { Container, ErrorDescription, ErrorImage, ErrorHeader } from "./Styles";
import {
  STOD2_REDIRECT,
  THEME,
  VODAFONE_REDIRECT,
} from "../../utility/constants";
import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/theme/selectors";
import { NormalText } from "../Shared/Shared";
import { Break } from "../Shared/Layout/Layout";

export default function Unauthorized() {
  const theme = useSelector(getTheme);
  rg4js("send", { error: "Landed on Unauthorized page" });
  window.analytics.page("Unauthorized");
  return theme !== THEME.VODAFONE ? (
    <Container>
      <ErrorHeader>Þú átt ekki að vera hérna</ErrorHeader>
      <ErrorImage>
        <img src={error} alt="Ekki réttindi" />
      </ErrorImage>
      <ErrorDescription style={{ maxWidth: "665px" }}>
        Þú hefur ekki réttindi að þessari síðu.
      </ErrorDescription>
      <LinkButton to={STOD2_REDIRECT} label="Fara á kaupsíðu stöð2.is" />
    </Container>
  ) : (
    <Container>
      <ErrorHeader>Úps... hvað gæti hafa gerst?</ErrorHeader>
      <NormalText style={{ maxWidth: "600px" }}>
        Þú ert ekki með aðgang að þessari síðu. Ef þú lendir áfram í vandræðum
        getur þú haft samband við okkur í gegnum Netspjallið eða þjónustuver
        Vodafone í síma 1414.
      </NormalText>
      <Break />
      <LinkButton to={VODAFONE_REDIRECT} label="Fara á vodafone.is" />
    </Container>
  );
}
