import {
  useMutation as useGraphMutation,
  useLazyQuery,
} from "@apollo/react-hooks";
import { isEmail } from "validator";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "@reach/router";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getActiveDonation } from "../../redux/reducers/donation/selectors";
import { getMenu } from "../../redux/reducers/menu/selectors";
import * as ReactMarkdown from "react-markdown";
import { setProcess } from "../../redux/reducers/process/actions";
import {
  getProduct,
  getSubscriptions,
  isProductPPV,
  isGiftCardAccepted,
  getProductList,
} from "../../redux/reducers/product/selectors";
import { getTheme } from "../../redux/reducers/theme/selectors";
import {
  setAuthenticated,
  setContentTerms,
  setEmailConfirmed,
} from "../../redux/reducers/user/actions";
import { getUser } from "../../redux/reducers/user/selectors";
import {
  getVoucherCode,
  isVoucherActive,
} from "../../redux/reducers/voucher/selectors";
import {
  MOBILE_BREAKPOINT,
  PROCESS_ACTION_CREATE,
  PROCESS_ACTION_DELETE,
  PROCESS_SUCCESS,
  TERMS_URL_TV_CONTENT,
  ELIGIBLE_FOR_NFL_AND_NBA,
  ELIGIBLE_FOR_NFL_01_09_22,
  MAX_CREDITCARD_POLL_COUNT,
  CREDITCARD_POLL_INTERVAL,
  PAYMENT_TYPES,
  ELIGIBLE_FOR_INTERNET_PLUS_BUNDLE,
  ALREADY_HAS_INTERNET_PLUS_BUNDLE,
  MINAR_SIDUR_URL,
} from "../../utility/constants";
import { Checkbox } from "../Shared/Checkbox/Checkbox";
import { color } from "../Shared/Color/colors";
import LinkButton from "../Shared/Link/Link";
import { Button, Layout, NormalText, Spinner } from "../Shared/Shared";
import {
  formatPrice,
  getFirstRatePeriod,
  getStubDate,
} from "../Shared/utility";
import ChannelConflict from "./ChannelConflict/ChannelConflict";
import Distribution from "./Distribution/Distribution";
import DonationSummary from "./DonationSummary/DonationSummary";
import PaymentForm from "./PaymentForm/PaymentForm";
import MonthlyPrice from "./MonthlyPrice/MonthlyPrice";
import {
  MODIFY_CUSTOMER,
  START_ORDER_CONTENT_PROCESS,
  putCreditCard,
  patchPaymentMethod,
} from "./mutations";
import PaymentType from "./PaymentType/PaymentType";
import UpSellItem from "./UpSellItem/UpSellItem";
import ProductItem from "./ProductItem/ProductItem";
import { GET_PRODUCTS_RATE, pollCreditCard, GET_UPSELL } from "./queries";
import { ConfirmButtonLabel, PriceItem } from "./Styles";
import {
  SummaryContainerBox,
  ProductContainerBox,
  PaymentContainerBox,
  TitleContainerBox,
  KartContainerBox,
  ProductListContainerBox,
  ListContainerBox,
  TermsContainerBox,
  SumContainerBox,
  PriceContainer,
  LargerTitle,
  LargeTitle,
  LargeLabel,
  DescriptionText,
  HelpIcon,
  Row,
  ButtonContainerBox,
  BackgroundTeal,
  HelpText,
  StickyBox,
} from "./Summary.styles";
import Voucher from "./Voucher/Voucher";
import ViaplayEmailForm from "./ViaplayEmailForm/ViaplayEmailForm";
import VirtualNumberPage from "./Payment/VirtualNumberPage/VirtualNumberPage";
import { checkValidToken } from "../../utility/auth";
import { useRestoreProduct, useRestoreVoucher } from "./hooks";
import { setProductList } from "../../redux/reducers/product/actions";

export default function Summary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector(getTheme);
  const product = useSelector(getProduct);
  const user = useSelector(getUser);
  const menu = useSelector(getMenu);
  const donation = useSelector(getActiveDonation);
  const ppv = useSelector(isProductPPV);
  const giftCard = useSelector(isGiftCardAccepted);
  const voucherCode = useSelector(getVoucherCode);
  const voucherActive = useSelector(isVoucherActive);
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });
  const subscriptions = useSelector(getSubscriptions);
  const token = checkValidToken();
  const productList = useSelector(getProductList);
  const [data, setData] = useState(null);
  const [upSell, setUpSell] = useState([]);
  const [hasConflict, setHasConflict] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [contentProcessStarted, setContentProcessStarted] = useState(false);
  const [pollCount, setPollCount] = useState(0);
  const [transactionId, setTransactionId] = useState(false);
  const [savedVoucherCode, setSavedVoucherCode] = useState(null);
  const [cardRegistered, setCardRegistered] = useState(false);
  const [creditCardInfo, setCreditCardInfo] = useState({
    cardNumber: "",
    expiryDate: "",
    cvc: "",
  });
  const [hasClickedConfirmPurchase, setHasClickedConfirmPurchase] = useState(
    false
  );
  const paymentBoxRef = useRef();
  const productBoxRef = useRef();

  const queryClient = useQueryClient();

  useEffect(() => {
    setSavedVoucherCode(localStorage.getItem("voucher_code"));
    const transactionIdParam = sessionStorage.getItem("transactionId");

    if (transactionIdParam) {
      setTransactionId(transactionIdParam);
      sessionStorage.removeItem("transactionId");
    }
  }, []);

  useEffect(() => {
    dispatch(setAuthenticated(!!token));
  }, [token, dispatch]);

  // When coming back to kaup.stod2.is from 3DSecure redirect
  useRestoreProduct(token);
  useRestoreVoucher(savedVoucherCode);

  const [productsRate, { loading: ratingLoading }] = useLazyQuery(
    GET_PRODUCTS_RATE,
    {
      onCompleted: (dataRef) => {
        setData(dataRef);
      },
      onError: () =>
        navigate("/error", {
          state: {
            errorMessage: "Mistókst að sækja verð á vörum",
          },
        }),
    }
  );

  const [upSellProducts, { loading: upSellLoading }] = useLazyQuery(
    GET_UPSELL,
    {
      onCompleted: (resData) => {
        setUpSell(resData?.upSellProposal);
      },
      onError: () =>
        navigate("/error", {
          state: {
            errorMessage: "Mistókst að sækja vörur",
          },
        }),
    }
  );

  const checkVoucher = () => {
    return ppv || giftCard ? voucherActive : true;
  };

  function prepareOrders(userRef) {
    const orders = [];
    // productIdlist and useremail are read from localstorage because the page might have refreshed for 3D-Secure creditcard stuff
    const productListObject = JSON.parse(localStorage.getItem("productIds"));
    const userEmail = localStorage.getItem("userEmail");
    const providerType = localStorage.getItem("providerType");
    const providerCustomerId = localStorage.getItem("providerCustomerId");
    if (
      productListObject?.productIds &&
      productListObject?.productIds?.length > 0
    ) {
      const list = productListObject?.productIds;
      list.forEach((id, index) => {
        const order = {
          productId: id,
          action: PROCESS_ACTION_CREATE,
          distributionProviderCode: providerType,
          distributionProviderCustomerIdentifier: providerCustomerId,
          billingId: userRef.billingId,
          phoneNumber: userRef.phonenumber,
          voucherCode,
          viaplayEmail: userEmail,
        };
        if (donation && index === 0) {
          order.offerProvider = donation.value;
        }
        orders.push(order);
      });
    }

    if (product.usingProposal && product.proposal?.terminations?.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const termination of product.proposal.terminations) {
        orders.push({
          action: PROCESS_ACTION_DELETE,
          mainResource: termination.mainResource,
          productId: termination.product.id,
        });
      }
    }
    return orders;
  }

  const [startContentProcess, { loading: processLoading }] = useGraphMutation(
    START_ORDER_CONTENT_PROCESS,
    {
      variables: {
        contentOrders: prepareOrders(user),
      },
      onCompleted: (dataRef) => {
        let contentProcess = null;
        // first process is the one that matters for 99% of the time
        if (dataRef?.startContentProcess?.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          contentProcess = dataRef.startContentProcess[0];
        }
        if (contentProcess.status === PROCESS_SUCCESS) {
          const { orderId, status } = contentProcess;

          dispatch(setProcess({ orderId, status }));
          navigate("/order/process");
        } else {
          navigate("/error", {
            state: {
              errorMessage: "Pöntunarferli mistókst",
            },
          });
        }
      },
      onError: () =>
        navigate("/error", {
          state: {
            errorMessage: "Pöntunarferli mistókst",
          },
        }),
    }
  );

  const [acceptTerms, { loading: termsLoading }] = useGraphMutation(
    MODIFY_CUSTOMER,
    {
      onCompleted: (dataRef) => {
        if (dataRef?.modifyCustomer?.id) {
          const customerQuery = queryClient.getQueryState(["customer"]);
          if (customerQuery.status === "success") {
            startContentProcess();
          }
        } else {
          navigate("/error", {
            state: {
              errorMessage: "Mistókst að samþykkja skilmála",
            },
          });
        }
      },
      onError: () =>
        navigate("/error", {
          state: {
            errorMessage: "Mistókst að samþykkja skilmála",
          },
        }),
      variables: {
        modification: {
          terms: [{ type: "STOD2", version: "1.0", accepted: true }],
        },
      },
    }
  );

  const patchPaymentMethodMutation = useMutation(patchPaymentMethod, {
    onSuccess: async (response) => {
      await queryClient.refetchQueries(["customer"]);
      if (!contentProcessStarted) {
        acceptTerms();
        setContentProcessStarted(true);
      }
    },
    onError: (error) => {
      navigate("/error", {
        state: {
          errorMessage: error.message,
        },
      });
    },
  });

  useQuery(["pollCreditCard", transactionId], pollCreditCard, {
    onSuccess: (response) => {
      switch (response.status) {
        case "SUCCESS":
          setCardRegistered(true);
          setPollCount(pollCount + 1);
          patchPaymentMethodMutation.mutate(PAYMENT_TYPES.CREDITCARD);
          break;
        case "PROCESSING":
          setPollCount(pollCount + 1);
          break;
        case "FAILURE":
          navigate("/error", {
            state: {
              errorMessage: response.message,
            },
          });
          break;
        default:
          navigate("/error");
          break;
      }
    },
    onError: (error) => {
      navigate("/error", {
        state: {
          errorMessage: error.message,
        },
      });
    },
    enabled:
      !!transactionId &&
      isPolling &&
      pollCount < MAX_CREDITCARD_POLL_COUNT &&
      user.authenticated &&
      user.distributionLoaded &&
      checkVoucher(),
    refetchInterval: CREDITCARD_POLL_INTERVAL,
  });

  const putCreditCardMutation = useMutation(putCreditCard, {
    onSuccess: (response) => {
      if (voucherCode) {
        localStorage.setItem("voucher_code", voucherCode);
      }
      sessionStorage.setItem("transactionId", response.transactionId);
      window.location = response.redirectUri;
    },
    onError: (error) => {
      navigate("/error", {
        state: {
          errorMessage: error.message,
        },
      });
    },
  });

  useEffect(() => {
    if (product?.parameters) {
      window.analytics.page("Summary");
      window.analytics.track("Checkout Started", {
        affiliation: `kaup.${theme}.is`,
        value: product.price.amount,
        revenue: product.price.amount,
        currency: "ISK",
        products: [
          {
            product_id: product.id,
            sku: product.parameters.find(
              (param) => param.name === "productCode"
            ).defaultValue,
            category: "1988",
            brand: theme,
            name: product.name,
            price: product.price.amount,
            quantity: 1,
            url: window.location.href,
            image_url: product.productInfo.imageUrl,
          },
        ],
      });
    }
  }, [product, theme]);

  useEffect(() => {
    if (product?.parameters && subscriptions) {
      const subs = subscriptions.map((sub) => {
        return {
          mainResource: sub.mainResource,
          productId: Number(sub.product.id),
        };
      });
      upSellProducts({
        variables: {
          productId: Number(product.id),
          currentSubscriptions: subs,
        },
      });
    }
  }, [product, upSellProducts, subscriptions]);

  useEffect(() => {
    setIsPolling(transactionId && !cardRegistered);
  }, [transactionId, cardRegistered]);

  useEffect(() => {
    const ProductIdList = productList.map((item) => {
      return { productId: Number(item.id) };
    });

    if (product?.parameters) {
      const beforeProducts = product.usingProposal
        ? product.proposal.terminations.map((term) => {
            return { productId: Number(term.product.id) };
          })
        : [];

      productsRate({
        variables: {
          input: {
            before: { products: beforeProducts },
            after: { products: ProductIdList },
          },
        },
      });
    }
  }, [productList, productsRate, product]);

  function shouldGetCheaperNFLProduct() {
    let stopIteration = false;
    let shouldGetCheaper = false;
    subscriptions.forEach((sub) => {
      if (stopIteration) {
        return;
      }
      ELIGIBLE_FOR_NFL_01_09_22.forEach((el) => {
        if (stopIteration) {
          return;
        }
        if (sub.product.id === el) {
          shouldGetCheaper = true;
          stopIteration = true;
        }
      });
    });
    return shouldGetCheaper;
  }
  // HUG-580 NFL 2021 (16.08.21)
  // HUG-1294 (23.08.22)
  if (product?.id === "44102" && product.subscriptions) {
    // Product ID for more expensive NFL Game Pass Pro product
    if (shouldGetCheaperNFLProduct()) {
      product.id = "44103"; // Product ID for cheaper NFL Game Pass Pro product
      localStorage.setItem("product_id", product.id);
    }
  }

  function shouldGetCheaperNBAProduct() {
    let stopIteration = false;
    let shouldGetCheaper = false;
    subscriptions.forEach((sub) => {
      if (stopIteration) {
        return;
      }
      ELIGIBLE_FOR_NFL_AND_NBA.forEach((el) => {
        if (stopIteration) {
          return;
        }
        if (sub.product.id === el) {
          shouldGetCheaper = true;
          stopIteration = true;
        }
      });
    });
    return shouldGetCheaper;
  }
  // HUG-727 NBA 2021 (15.10.21)
  if (product?.id === "44104" && product.subscriptions) {
    // Product ID for more expensive NBA League Pass Premium product
    if (shouldGetCheaperNBAProduct()) {
      product.id = "44105"; // Product ID for cheaper NBA League Pass Premium product
      localStorage.setItem("product_id", product.id);
    }
  }

  function shouldGetInternetPlusBundle() {
    let shouldGet = false;
    const alreadyHas = subscriptions.filter((sub) =>
      ALREADY_HAS_INTERNET_PLUS_BUNDLE.includes(sub.product.id)
    );
    if (alreadyHas.length > 0) {
      return;
    } else {
      subscriptions.forEach((sub) => {
        if (shouldGet) return;
        ELIGIBLE_FOR_INTERNET_PLUS_BUNDLE.forEach((el) => {
          if (shouldGet) return;
          if (sub.product.id === el) {
            shouldGet = true;
            return;
          }
        });
      });
    }
    return shouldGet;
  }
  if (product?.id === "41319" && product.subscriptions) {
    if (shouldGetInternetPlusBundle()) {
      product.id = "43460"; // Product ID for Stöð 2 með Internet Plús
      localStorage.setItem("product_id", product.id);
    }
  }

  // Default columns 4
  function getColumnCount(columns = 4) {
    if (user.hasDiscount) {
      columns++;
    }
    if (product.binding?.endDate) {
      columns++;
    }
    return columns;
  }

  if (!token) {
    navigate("/unauthorized");
    return <></>;
  }

  const handleCardInfo = (cardInfo) => {
    setCreditCardInfo(cardInfo);
  };

  const addToKart = (productItem) => {
    dispatch(setProductList([...productList, productItem]));
    // need to store the productIds in localstorage since the user might get redirected (3dsecure)
    localStorage.setItem(
      "productIds",
      JSON.stringify({
        productIds: [...productList, productItem].map((x) => x.id),
      })
    );
  };

  const removeFromKart = (productItem) => {
    const tempList = productList.filter((item) => item.id !== productItem.id);
    dispatch(setProductList(tempList));
    // need to store the productIds in localstorage since the user might get redirected (3dsecure)
    localStorage.setItem(
      "productIds",
      JSON.stringify({ productIds: tempList.map((x) => x.id) })
    );
  };

  const submitPayment = () => {
    localStorage.setItem("userEmail", user.email);
    setHasClickedConfirmPurchase(true);
    if (user.selectedPaymentType === "NEW_CARD" && creditCardInfo.cardNumber) {
      putCreditCardMutation.mutate(creditCardInfo);
    } else {
      patchPaymentMethodMutation.mutate(user.selectedPaymentType);
    }
  };
  const validateEmail = () => {
    if (product?.emailRequired) {
      return user.emailConfirmed && isEmail(user?.email);
    }
    return true;
  };

  return (
    <Layout.CenteredContainer>
      <SummaryContainerBox>
        {product.id && <Distribution />}
        {!user.paymentTypeLoaded && (
          <PaymentType
            cardRegistered={cardRegistered}
            transactionId={transactionId}
          />
        )}
        {(!user.distributionLoaded ||
          !user.paymentTypeLoaded ||
          transactionId) && <Spinner />}
        {!product?.id && !ratingLoading && user.distributionLoaded && (
          <NormalText>
            Engin vara fannst. Vinsamlegast byrjaðu aftur á ferlinu frá byrjun.
          </NormalText>
        )}
        {product?.id &&
          data &&
          !transactionId &&
          user.distributionLoaded &&
          user.paymentTypeLoaded && (
            <>
              <ProductContainerBox>
                <TitleContainerBox>
                  <LargerTitle>Karfan mín</LargerTitle>
                  <LargeLabel>Kláraðu kaupinn og byrjaðu að horfa</LargeLabel>
                </TitleContainerBox>
                {(!menu.displayProposal || product.skipProposal) && (
                  <ChannelConflict handleConflict={setHasConflict} />
                )}
                <KartContainerBox
                  size={productList.length}
                  conflict={hasConflict}
                >
                  {productList.map((productItem, index) => (
                    <ProductItem
                      key={productItem.id}
                      product={productItem}
                      conflict={index === 0 && hasConflict}
                    />
                  ))}
                </KartContainerBox>
                {!upSellLoading && upSell.length > 0 && (
                  <>
                    {product?.productInfo?.upsellText && (
                      <TitleContainerBox>
                        <LargeLabel>
                          {product?.productInfo?.upsellTextTitle ?? "Tilboð"}
                        </LargeLabel>
                        <DescriptionText>
                          {product?.productInfo?.upsellText}
                        </DescriptionText>
                      </TitleContainerBox>
                    )}
                    <ProductListContainerBox>
                      {upSell.length > 0 &&
                        upSell.map((upsellItem) => (
                          <UpSellItem
                            key={upsellItem.id}
                            product={upsellItem}
                            add={addToKart}
                            remove={removeFromKart}
                          />
                        ))}
                    </ProductListContainerBox>
                  </>
                )}
              </ProductContainerBox>
              <div ref={productBoxRef} />
            </>
          )}
        {product?.id &&
          data &&
          user.distributionLoaded &&
          !transactionId &&
          user.paymentTypeLoaded && (
            <>
              <PaymentContainerBox ref={paymentBoxRef}>
                <StickyBox>
                  <TitleContainerBox>
                    <LargeTitle>Greiðslu upplýsingar</LargeTitle>
                  </TitleContainerBox>
                  <ListContainerBox>
                    <LargeLabel>Veldu greiðlsumáta</LargeLabel>
                    <PaymentForm
                      callback={(cardInfo) => handleCardInfo(cardInfo)}
                    />
                  </ListContainerBox>
                  {ppv ||
                    (giftCard && (
                      <ListContainerBox>
                        <Row>
                          <LargeLabel>Afsláttur </LargeLabel>
                          <HelpIcon>
                            <img src="/images/questionMark.svg" alt="help" />
                            <HelpText>
                              Hér kemur síðan texti fyrir hvað getur farið hér
                              inn.
                            </HelpText>
                          </HelpIcon>
                        </Row>
                        <Voucher />
                      </ListContainerBox>
                    ))}

                  <SumContainerBox>
                    <LargeLabel>Samtals</LargeLabel>
                    <PriceContainer>
                      {ratingLoading ? (
                        <Spinner size={50} />
                      ) : (
                        <>
                          {!ppv && !giftCard ? (
                            <PriceItem>
                              {product.singleMonth
                                ? formatPrice(
                                    getFirstRatePeriod(data).actualPrice
                                  )
                                : formatPrice(
                                    getFirstRatePeriod(data)
                                      ? getFirstRatePeriod(data).actualPrice
                                      : null
                                  )}{" "}
                              kr.
                            </PriceItem>
                          ) : (
                            <MonthlyPrice />
                          )}
                          <DescriptionText>
                            {!product.singleMonth && getStubDate(data)}.
                          </DescriptionText>
                        </>
                      )}
                    </PriceContainer>
                  </SumContainerBox>
                  {product?.emailRequired && (
                    <>
                      <ListContainerBox>
                        <Layout.RulerSpacy space={"10px"} />
                        <Row>
                          <LargeLabel>Netfang vegna Viaplay </LargeLabel>
                          <HelpIcon>
                            <img src="/images/questionMark.svg" alt="help" />
                            <HelpText>
                              Þetta netfang verður notað til innskráningar hjá
                              Viaplay
                            </HelpText>
                          </HelpIcon>
                        </Row>
                        <ViaplayEmailForm />
                      </ListContainerBox>

                      <TermsContainerBox>
                        <Checkbox
                          label={
                            <LinkButton label="Ég staðfesti að þetta netfang verður notað hjá Viaplay" />
                          }
                          callback={() =>
                            dispatch(setEmailConfirmed(!user.emailConfirmed))
                          }
                          initialValue={user.emailConfirmed}
                        />
                      </TermsContainerBox>
                    </>
                  )}
                  <TermsContainerBox>
                    <Checkbox
                      label={
                        <LinkButton
                          to={TERMS_URL_TV_CONTENT}
                          label="Ég samþykki áskriftarskilmála Stöðvar 2"
                        />
                      }
                      callback={() =>
                        dispatch(setContentTerms(!user.contentTerms))
                      }
                      initialValue={user.contentTerms}
                    />
                  </TermsContainerBox>
                  <ButtonContainerBox>
                    <Button
                      active={
                        user.contentTerms &&
                        product?.id &&
                        !processLoading &&
                        !termsLoading &&
                        user.paymentTypeConfirmed &&
                        validateEmail()
                      }
                      padding="24px 0px"
                      width="100"
                      disabled={hasClickedConfirmPurchase}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          product?.id &&
                          user?.contentTerms &&
                          !processLoading &&
                          !termsLoading &&
                          user.paymentTypeConfirmed &&
                          validateEmail()
                        ) {
                          submitPayment();
                        }
                      }}
                    >
                      <ConfirmButtonLabel>
                        {(processLoading ||
                          putCreditCardMutation.isLoading ||
                          putCreditCardMutation.isSuccess) && (
                          <Spinner color={color.white} size={18} />
                        )}
                        &nbsp;STAÐFESTA KAUP
                      </ConfirmButtonLabel>
                    </Button>
                  </ButtonContainerBox>
                </StickyBox>
                <BackgroundTeal />
              </PaymentContainerBox>
            </>
          )}
      </SummaryContainerBox>
    </Layout.CenteredContainer>
  );
}
