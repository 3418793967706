import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";
import { color } from "../Color/colors";

const LargeHeader = styled.h1`
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 0px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 40px;
  }
`;

const SubHeader = styled.div`
  font-size: 30px;
  font-weight: 700;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 24px;
  }
`;

const SubHeader2 = styled(SubHeader)`
  letter-spacing: 0.5px;
  color: ${color.nero};
`;

const SmallHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 3px 0;
`;

export { LargeHeader, SubHeader, SmallHeader, SubHeader2 };
