import {
  SET_PROCESS,
  SET_PROCESS_STATUS,
  SET_PROCESS_ERROR,
  PROCESS_INCREMENT_POLL_COUNT,
  PROCESS_CLEAR_POLL_COUNT,
} from "./actions";

const initialState = {
  orderId: null,
  status: null,
  error: false,
  pollCount: 0,
};

export default function processReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROCESS:
      return {
        ...state,
        ...action.process,
      };
    case SET_PROCESS_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case SET_PROCESS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case PROCESS_CLEAR_POLL_COUNT:
      return {
        ...state,
        pollCount: initialState.pollCount,
      };
    case PROCESS_INCREMENT_POLL_COUNT:
      return {
        ...state,
        pollCount: state.pollCount + 1,
      };
    default:
      return state;
  }
}
