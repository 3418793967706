import styled from "styled-components";
import {
  MOBILE_BREAKPOINT,
  LAYOUT_MAX_WIDTH,
  THEME,
} from "../../utility/constants";
import { color } from "../Shared/Color/colors";

const TopLevelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: ${LAYOUT_MAX_WIDTH};
  padding: 50px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    max-width: none;
    padding: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr);` : `repeat(4, 1fr);`};
  grid-gap: 5px;
  grid-auto-rows: auto;
  width: 100%;
  margin-top: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 20px;
  }
`;

const SummaryLabel = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
  color: ${color.black};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 5px;
  }
`;

const Price = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const PriceLabel = styled.div`
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  color: ${color.black};
`;

const PriceItem = styled.div`
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: ${color.black};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 10px;
  }
`;

const ConfirmButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ProposalBox = styled.div`
  min-height: 630px;
  max-width: 665px;
  border-radius: 4px;
  background-color: #fff;
  margin: 5px;
  .active {
    background-color: ${(props) =>
      props.theme === THEME.VODAFONE ? color.vodaRed : "#205257"};
    color: white;
  }
`;
const ProposalBoxTop = styled.div`
  min-height: 122px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  div {
    .active {
      color: white;
    }
  }
  cursor: pointer;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    min-height: 175px;
  }
`;
const ProposalBoxMiddle = styled.div`
  min-height: 445px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
`;
const ProposalBoxBottom = styled.div`
  min-height: 72px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`;
const ProposalBoxSubHeader = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 9px;
`;
const ProposalBoxLabel = styled.span`
  color: #181717;
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const ProposalBoxPackage = styled.span`
  width: 87px;
  color: ${(props) =>
    props.theme === THEME.VODAFONE ? color.nero : color.greenish};
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  width: 100%;
  height: 100%;
  min-height: 35px;
`;
const ProposalBoxSummary = styled.span`
  height: 42px;
  width: 631px;
  color: #181717;
  font-size: 16px;
  line-height: 20px;
`;
const ProposalSummaryPrice = styled.span`
  font-size: 42px;
  color: ${(props) =>
    props.theme === THEME.VODAFONE ? color.nero : color.greenish};
`;
const ProposalSummaryPriceWrapper = styled.div`
  font-size: 42px;
`;
const ProposalCurrentPrice = styled.span`
  color: #181717;
  font-size: 28px;
  font-weight: 300;
  text-align: right;
  width: 100%;
`;
const ProposalRadioWrapper = styled.div`
  width: 20px;
  position: absolute;
  right: 10px;
  top: 20px;
`;
const RadioContainer = styled.div`
  position: relative;
`;
export {
  Price,
  PriceItem,
  PriceLabel,
  SummaryItem,
  SummaryLabel,
  ConfirmButtonLabel,
  TopLevelContainer,
  ProposalBox,
  ProposalBoxTop,
  ProposalBoxSubHeader,
  ProposalBoxLabel,
  ProposalCurrentPrice,
  ProposalBoxPackage,
  ProposalBoxSummary,
  ProposalBoxMiddle,
  ProposalBoxBottom,
  ProposalSummaryPrice,
  ProposalSummaryPriceWrapper,
  ProposalRadioWrapper,
  RadioContainer,
  Container,
  Grid,
};
