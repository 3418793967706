import { gql } from "apollo-boost";

export const ORDER_PHONECALL = gql`
  mutation orderPhoneCall(
    $productName: String
    $fullName: String!
    $email: String!
    $phoneNumber: String!
    $nationalId: String!
  ) {
    orderPhoneCall(
      productName: $productName
      fullName: $fullName
      email: $email
      phoneNumber: $phoneNumber
      nationalId: $nationalId
    ) {
      success
    }
  }
`;
