import styled, { keyframes } from "styled-components";
import { color } from "../../Shared/Color/colors";

const popIn = keyframes`
 from { 
  opacity: 0;
  transform: translateY(50%);
}
 to { 
  opacity: 1;
  transform: translateY(0%);
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FormContainer = styled.div`
  width: 100%;
  background: "transparent";
  box-shadow: none;
`;

const RowItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RowItem = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "5px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "5px"};
`;

const CreditCardInputContainer = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid #c1c7d0;
  height: 40px;
  width: 100%;
  align-items: center;
  background-color: ${color.antiFlashWhite};
`;

const CreditCardInput = styled.input`
  background-color: inherit;
  width: 100%;
  border: unset;
  margin: unset;
  padding: unset;
  outline: unset;
  padding-left: 0.6em;
  line-height: 24px;
  font-weight: 200;
`;

const CreditCardSvg = styled.svg`
  padding-left: 10px;
`;

const HiddenItem = styled.div`
  display: none;
`;

const FormSlider = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => (props.show ? 200 : 0)}px;
  padding-right: 5px;
  transition: all 0.5s ease;
`;

const PayIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
`;

const TextContainer = styled.div`
  animation: 0.5s ${popIn} ease;
`;

export {
  Form,
  FormContainer,
  RowItems,
  RowItem,
  HiddenItem,
  CreditCardInput,
  CreditCardInputContainer,
  CreditCardSvg,
  FormSlider,
  PayIcon,
  TextContainer,
};
