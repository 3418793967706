import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDistribution,
  setUserTvSystemProperties,
} from "../../../redux/reducers/user/actions";
import { GET_DISTRIBUTION } from "./queries";
import { getProduct } from "../../../redux/reducers/product/selectors";

export default function Distribution() {
  const product = useSelector(getProduct);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useQuery(GET_DISTRIBUTION, {
    variables: {
      productId: Number(product.id),
    },
    onError: () =>
      navigate("/error", {
        state: {
          errorMessage: "Mistókst að sækja dreifileið",
        },
      }),
  });

  useEffect(() => {
    if (data) {
      let distribution = {}; // if there are no distributions available just return this
      if (data.rankedDistributions?.length > 0) {
        // always selecting the first item for now, later will maybe allow the user to choose
        distribution = data.rankedDistributions[0];
      }
      dispatch(
        setDistribution(
          distribution.providerCustomerId,
          distribution.providerType
        )
      );
      localStorage.setItem(
        "providerCustomerId",
        distribution.providerCustomerId
      );
      localStorage.setItem("providerType", distribution.providerType);
      dispatch(
        setUserTvSystemProperties(
          distribution.tvSystemProperties
            ? distribution.tvSystemProperties.hasOttUser
            : false,
          distribution.equipmentCount
        )
      );
    }
  }, [data, dispatch]);

  return null;
}
