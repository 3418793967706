import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPaymentTypeConfirmed,
  setSelectedPaymentType,
} from "../../../redux/reducers/user/actions";
import { ButtonGroup } from "../../Shared/Button/Button";
import { WideRadioButton } from "../../Shared/WideRadioButton/WideRadioButton";
import { getUser } from "../../../redux/reducers/user/selectors";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import { PAYMENT_TYPES } from "../../../utility/constants";
import CreditCardForm from "../CreditCardForm/CreditCardForm";
import { PayIcon, Dot, Fill, FormSlider } from "./Styles";

export default function PaymentTypeSelect(props) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const theme = useSelector(getTheme);

  function handleChange(selected) {
    dispatch(setSelectedPaymentType(selected));

    if (selected === PAYMENT_TYPES.CREDITCARD) {
      dispatch(setPaymentTypeConfirmed(true));
    } else if (selected === PAYMENT_TYPES.NEW_CARD) {
      dispatch(setPaymentTypeConfirmed(false));
    } else {
      window.analytics.track("Payment Info Entered", {
        payment_method: selected.value,
      });
      dispatch(setPaymentTypeConfirmed(true));
    }
  }

  const callbackCardInfoToParent = (cardInfo) => {
    props.callback(cardInfo);
  };

  return (
    <ButtonGroup
      justifyContent={user.maskedCreditCard ? "space-between" : "flex-start"}
    >
      {user.maskedCreditCard && (
        <WideRadioButton
          minHeight="52px"
          minWidth="100%"
          maxWidth="100%"
          marginRight="0"
          theme={theme}
          callback={(selected) =>
            selected ? handleChange(PAYMENT_TYPES.CREDITCARD) : null
          }
          selected={user.selectedPaymentType === PAYMENT_TYPES.CREDITCARD}
        >
          <PayIcon>
            <img src="/images/credit-card.svg" alt="card" />
          </PayIcon>
          {user.cardType ?? ""} ({user.maskedCreditCard.slice(-4)})
          <Dot checked={user.selectedPaymentType === PAYMENT_TYPES.CREDITCARD}>
            <Fill
              checked={user.selectedPaymentType === PAYMENT_TYPES.CREDITCARD}
            />
          </Dot>
        </WideRadioButton>
      )}
      <WideRadioButton
        minHeight="52px"
        minWidth="100%"
        maxWidth="100%"
        theme={theme}
        callback={(selected) =>
          selected ? handleChange(PAYMENT_TYPES.NEW_CARD) : null
        }
        selected={user.selectedPaymentType === PAYMENT_TYPES.NEW_CARD}
      >
        <PayIcon>
          <img src="/images/payment-method.svg" alt="new-card" />
        </PayIcon>
        Nýtt kort
        <Dot checked={user.selectedPaymentType === PAYMENT_TYPES.NEW_CARD}>
          <Fill checked={user.selectedPaymentType === PAYMENT_TYPES.NEW_CARD} />
        </Dot>
      </WideRadioButton>
      <FormSlider show={user.selectedPaymentType === "NEW_CARD"}>
        {user.selectedPaymentType === "NEW_CARD" && (
          <CreditCardForm
            callback={(cardInfo) => callbackCardInfoToParent(cardInfo)}
          />
        )}
      </FormSlider>
      <WideRadioButton
        minHeight="52px"
        minWidth="100%"
        maxWidth="100%"
        theme={theme}
        callback={(selected) =>
          selected ? handleChange(PAYMENT_TYPES.ELECTRONIC) : null
        }
        selected={user.selectedPaymentType === PAYMENT_TYPES.ELECTRONIC}
      >
        <PayIcon>
          <img src="/images/cheque.svg" alt="bank" />
        </PayIcon>
        Fá reikning í heimabanka
        <Dot checked={user.selectedPaymentType === PAYMENT_TYPES.ELECTRONIC}>
          <Fill
            checked={user.selectedPaymentType === PAYMENT_TYPES.ELECTRONIC}
          />
        </Dot>
      </WideRadioButton>
    </ButtonGroup>
  );
}
