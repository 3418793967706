import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  setAuthenticated,
  setUser,
  setUserPhonenumber,
  setGDPRTerms,
} from "../../redux/reducers/user/actions";
import { getUser } from "../../redux/reducers/user/selectors";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import { color } from "../Shared/Color/colors";
import { Input } from "../Shared/Input/Input";
import InputError from "../Shared/Input/InputError";
import { InputErrorWrap } from "../Shared/Input/Styles";
import {
  Button,
  LabelText,
  LargeHeader,
  Layout,
  NormalText,
  Spinner,
  SubHeader,
} from "../Shared/Shared";
import { LOGIN } from "./mutations";
import { GET_CUSTOMER } from "./queries";
import { ButtonRow, EID as Container, Form } from "./Styles";
import { ageLimit } from "../Shared/utility";
import { getMenu } from "../../redux/reducers/menu/selectors";
import {
  isProductPPV,
  getProduct,
} from "../../redux/reducers/product/selectors";
import { setProductList } from "../../redux/reducers/product/actions";
import { convertSsnToGuid } from "../../utility/guid.js";

export default function EidForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const menu = useSelector(getMenu);
  const ppv = useSelector(isProductPPV);

  const product = useSelector(getProduct);

  const { register, errors, triggerValidation, watch } = useForm({
    mode: "onChange",
  });
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  const [login, { loading: loggingIn }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data?.loginUsingEid?.claim) {
        const { claim } = data.loginUsingEid;
        customer({ variables: { claim } });
      } else {
        navigate("/error", {
          state: {
            errorMessage: "Innskráning mistókst",
          },
        });
      }
    },
    onError: (error) => handleError(error),
  });

  const [customer, { loading: fetchingCustomer }] = useLazyQuery(GET_CUSTOMER, {
    onCompleted: async (data) => {
      if (data?.customer?.token) {
        loginHandler(data.customer);
      } else {
        navigate("/error", {
          state: {
            errorMessage: "Mistókst að auðkenna notanda",
          },
        });
      }
    },
    onError: () =>
      navigate("/error", {
        state: {
          errorMessage: "Mistókst að sækja upplýsingar um notanda",
        },
      }),
  });

  function loginHandler(customer) {
    let { token, phonenumber, nationalID, terms, email } = customer;
    if (!ageLimit(nationalID)) {
      setErrorMessage(
        "Kaupendur sjónvarpsþjónustu þurfa að vera 18 ára eða eldri."
      );
      return;
    }
    const loginPhonenumber = user.phonenumber;
    localStorage.setItem("id_token", token);
    dispatch(setUser(customer));
    if (phonenumber !== loginPhonenumber) {
      dispatch(setUserPhonenumber(loginPhonenumber));
    }
    dispatch(setAuthenticated(true));

    convertSsnToGuid(nationalID).then(function (data) {
      if (data.guid) {
        window.analytics.identify(data.guid);
        window.analytics.track("Logged In", {
          user_id: data.guid,
        });
      }
    });

    if (terms?.find((term) => term.type === "GDPR" && term.accepted) && email) {
      dispatch(setGDPRTerms(true));
      dispatch(setProductList([product]));
      localStorage.setItem(
        "productIds",
        JSON.stringify({ productIds: [product.id] })
      );

      if (menu.displayProposal) {
        navigate("/order/proposal");
      } else {
        navigate("/order/summary");
      }
    } else {
      navigate("/user");
    }
  }

  // TODO: Only use friendly.
  function handleError(error) {
    error.graphQLErrors.forEach(({ extensions }) => {
      const { code } = extensions;
      const friendly = isNaN(code);
      switch (code) {
        case friendly ? "CANCELLED" : "1":
          setErrorMessage("Notandi hætti við.");
          break;
        case friendly ? "DEADLINE_EXCEEDED" : "4":
          setErrorMessage("Auðkenning tók of langan tíma, reyndu aftur.");
          break;
        case friendly ? "NOT_FOUND" : "5":
          setErrorMessage("Notandi er ekki með virk rafræn skilríki.");
          break;
        default:
          navigate("/error");
      }
    });
  }

  return (
    <Container>
      <LargeHeader>Innskráning</LargeHeader>
      <SubHeader>Rafræn skilríki í síma</SubHeader>
      <Layout.Break size={"20px"} />
      <NormalText>
        Skráðu þig inn til að kaupa {ppv ? "viðburð" : "áskrift"}.
      </NormalText>
      <Layout.Break size={isTabletOrMobile ? "20px" : "60px"} />
      <LabelText>Símanúmer*</LabelText>
      <Form>
        <Input
          name="phonenumber"
          autoFocus={true}
          autoComplete="off"
          type="text"
          maxLength={7}
          maxWidth={isTabletOrMobile ? "60%" : null}
          flexGrow={isTabletOrMobile ? "2" : null}
          marginRight={isTabletOrMobile ? "0px" : "10px"}
          ref={register({
            required: true,
            pattern: {
              value: /^[6-9]\d{6}$/,
              message: "Farsímanúmer ekki á réttu formi.",
            },
          })}
        />
        <InputErrorWrap>
          {isTabletOrMobile && watch("phonenumber")?.length === 7 && (
            <InputError
              message={errorMessage ?? errors?.phonenumber?.message}
            />
          )}
        </InputErrorWrap>
        <Layout.Break />
        <Button
          width={isTabletOrMobile ? "100" : null}
          active={!errors.phonenumber && !loggingIn && !fetchingCustomer}
          onClick={async (e) => {
            e.preventDefault();
            const valid = await triggerValidation("phonenumber");
            if (valid && !loggingIn && !fetchingCustomer) {
              const phonenumber = watch("phonenumber");
              dispatch(setUserPhonenumber(phonenumber));
              login({ variables: { phonenumber } });
            }
          }}
        >
          <ButtonRow>
            {(loggingIn || fetchingCustomer) && (
              <Spinner color={color.white} size={18} />
            )}
            &nbsp; INNSKRÁ
          </ButtonRow>
        </Button>
      </Form>
      <InputErrorWrap>
        {!isTabletOrMobile && watch("phonenumber")?.length === 7 && (
          <InputError message={errorMessage ?? errors?.phonenumber?.message} />
        )}
      </InputErrorWrap>
    </Container>
  );
}
