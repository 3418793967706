import {
  gql
} from "apollo-boost";

const SELLABLE_BY_PRODUCT_ID = gql `
  query sellableByProductId($productId: Int!) {
    sellableByProductId(productId: $productId) {
      id
      name
      endDateFriendly
      singleMonth
      voucher
      emailRequired
      price {
        amount
      }
      productInfo {
        title
        titleBeyging
        name
        shortDescription
        longDescription
        imageUrl
        iconUrl
        iconsUrls
        subheader
        whereToWatchDescription
        bindingContractText
        resultText
        upsellText
        upsellTextTitle
      }
      parameters {
        name
        defaultValue
      }
      binding {
        period
        periodFriendly
        startDate
        endDate
      }
      children {
        id
        name
        singleMonth
        endDate
        endDateFriendly
        binding {
          period
          periodFriendly
          startDate
          endDate
        }
        parameters {
          id
          name
          defaultValue
        }
        price {
          amount
        }
        productInfo {
          title
          name
          shortDescription
          longDescription
          imageUrl
          iconUrl
          iconsUrls
          subheader
          whereToWatchDescription
          bindingContractText
        }
      }
    }
  }
`;

const GET_DONATIONS = gql `
  query donations($productId: Int!) {
    donations(productId: $productId) {
      id
      title
      amount
      description
      thankYouText
      payer
      paymentMethod
      placeholders
      donationProductCatalogId
      active
      checkboxLabel
      options {
        type
        label
        value
      }
      types {
        label
        value
      }
    }
  }
`;

export {
  SELLABLE_BY_PRODUCT_ID,
  GET_DONATIONS
};