import * as moment from "moment";
import "moment/locale/is";
import rg4js from "raygun4js";

const formatPrice = (price) =>
  Intl.NumberFormat().format(price).replace(",", ".");

const getFirstRatePeriod = (data) => {
  if (data?.productsRate?.periods?.length > 0) {
    return data.productsRate.periods.find((p) => p.firstPeriod === "true");
  }
  return null;
};

const getStubDate = (data) => {
  const firstPeriod = getFirstRatePeriod(data);
  if (firstPeriod) {
    const { dateFrom } = firstPeriod;
    return `${moment(dateFrom).format("D")}.-${moment(dateFrom)
      .endOf("month")
      .format("D")}. ${moment(dateFrom).format("MMM")}`;
  }
  return null;
};

const ageLimit = (nationalId, limit = 18) => {
  try {
    if (!nationalId) {
      return false;
    }
    let birthday = nationalId.slice(0, 6);
    const centuryCode = nationalId[nationalId.length - 1];
    let century;
    if (centuryCode === "0") {
      century = 20;
    } else if (centuryCode === "9") {
      century = 19;
    } else {
      century = 18;
    }
    birthday = birthday.slice(0, 4) + century + birthday.slice(4, 6);
    let years = parseInt(moment(birthday, "DDMMYYYY").fromNow(true));
    return years >= limit;
  } catch (e) {
    rg4js("send", { error: `Age limit warning: ${e.message}` });
    return false;
  }
};

function getTerminationProductText(terms) {
  let products = terms.map((term) => {
    return term.product;
  });
  return getProductPackageText(products);
}

function getProductPackageText(products) {
  if (!products || products.length < 1) {
    return "";
  }
  return products.map((prod, i) => {
    if (products.length - 1 === i && products.length > 1) {
      return ` og ${prod.name}`;
    } else {
      if (i > 0) {
        return `, ${prod.name}`;
      } else {
        return prod.name;
      }
    }
  });
}

function getGraphQLErrorCodes(error) {
  let errorCodes = [];
  if (error?.graphQLErrors?.length > 0) {
    for (const graphQLError of error.graphQLErrors) {
      errorCodes.push(graphQLError.extensions.code);
    }
  }
  return errorCodes;
}

function getDiscountPercentage(price, listPrice) {
  return Math.round((listPrice / (listPrice - price)) * 100);
}

export {
  getFirstRatePeriod,
  formatPrice,
  getStubDate,
  ageLimit,
  getProductPackageText,
  getTerminationProductText,
  getGraphQLErrorCodes,
  getDiscountPercentage,
};
