import { gql } from "apollo-boost";

const GET_VIRTUAL_NUMBER_LINK = gql`
  query virtualNumberLink($billingId: String){
  virtualNumberLink(billingId: $billingId) {
    url
    billingId
  }
}
`;

export { GET_VIRTUAL_NUMBER_LINK };
