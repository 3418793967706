const prefix = "donation/";
const SET_DONATIONS = `${prefix}SET_DONATIONS`;
const ACTIVATE_DONATION = `${prefix}ACTIVATE_DONATION`;
const SET_ACTIVE_DONATION_TYPE = `${prefix}SET_ACTIVE_DONATION_TYPE`;
const CLEAR_ACTIVE_DONATION = `${prefix}CLEAR_ACTIVE_DONATION`;
const SET_IS_DONATING = `${prefix}SET_IS_DONATING`;
const SET_DONATION_PRODUCT = `${prefix}SET_DONATION_PRODUCT`;
const CLEAR_DONATION_PRODUCT = `${prefix}CLEAR_DONATION_PRODUCT`

const setDonations = (donations) => ({
  type: SET_DONATIONS,
  donations,
});

const activateDonation = (donation) => ({
  type: ACTIVATE_DONATION,
  donation,
});

const setActiveDonationType = (donationType) => ({
  type: SET_ACTIVE_DONATION_TYPE,
  donationType,
});

const clearActiveDonation = () => ({
  type: CLEAR_ACTIVE_DONATION
});

const setIsDonating = (donating) => ({
  type: SET_IS_DONATING,
  donating
});

const setDonationProduct = (product) => ({
  type: SET_DONATION_PRODUCT,
  product
});

const clearDonationProduct = () => ({
  type: CLEAR_DONATION_PRODUCT,
});

export {
  SET_DONATIONS,
  ACTIVATE_DONATION,
  SET_ACTIVE_DONATION_TYPE,
  CLEAR_ACTIVE_DONATION,
  SET_IS_DONATING,
  SET_DONATION_PRODUCT,
  CLEAR_DONATION_PRODUCT,
  activateDonation,
  setDonations,
  setActiveDonationType,
  clearActiveDonation,
  setIsDonating,
  setDonationProduct,
  clearDonationProduct
};
