import { useLazyQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../redux/reducers/product/selectors";
import { GET_PRODUCT_PROPOSAL } from "./queries";
import { useEffect } from "react";
import {
  setProposal,
  setProposalLoading,
  skipProposal,
} from "../../redux/reducers/product/actions";
import { getMenu } from "../../redux/reducers/menu/selectors";
export default function ProductProposal({ subscriptions }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector(getProduct);
  const menu = useSelector(getMenu);
  let subs = subscriptions.map((sub) => {
    return {
      mainResource: sub.mainResource,
      productId: Number(sub.product.id),
    };
  });
  // if fakedOldProductId is provided then we fake what the user has these subs
  if (menu.fakedOldProductIds) {
    let array = menu.fakedOldProductIds.split(",");
    subs = array.map((productId) => {
      return {
        mainResource: `faked-mainresource-${productId}`,
        productId: Number(productId),
      };
    });
  }
  const [getProductProposal, { data }] = useLazyQuery(GET_PRODUCT_PROPOSAL, {
    variables: {
      productId: Number(product.id),
      currentSubscriptions: subs,
    },
    onError: () => navigate("/error"),
    onCompleted: () => {
      if (!data.productProposal || !data.productProposal.product) {
        dispatch(skipProposal());
        navigate("/order/summary");
      } else {
        dispatch(setProposal(data.productProposal));
        dispatch(setProposalLoading(false));
      }
    },
  });
  useEffect(() => getProductProposal(), [getProductProposal]);

  return null;
}
