const getUser = (state) => state.user;
const getUserEmail = (state) => state.user.email;
const getUserPhonenumber = (state) => state.user.phoneNumber;
const getUserName = (state) => state.user.fullName;
const getUserContentTerms = (state) => state.user.contentTerms;
const getUserGdprTerms = (state) => state.user.gdprTerms;
const getEmailConfirmed = (state) => state.user.emailConfirmed;
const isAuthenticated = (state) => state.user.authenticated;

export {
  getUser,
  getUserEmail,
  getUserPhonenumber,
  getUserName,
  getUserContentTerms,
  getUserGdprTerms,
  getEmailConfirmed,
  isAuthenticated
};
