import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import { Input } from "../Shared/Input/Input";

const PhoneCall = styled.div`
  width: 100%;
  padding: 50px 100px 100px 100px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 20px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  grid-auto-rows: auto;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
  }
`;

const FormItem = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 20px;
  }
`;

const PhoneCallInput = styled(Input)`
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 90%;
  }
`;

export { PhoneCall, Form, FormItem, PhoneCallInput };
