import { createGlobalStyle } from "styled-components";
import { THEME } from "./utility/constants";
import { color } from "./components/Shared/Color/colors";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) =>
      props.theme?.theme === THEME.VODAFONE ? "Vodafone" : "Muli"};
    background-color: #f4f4f4;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    button {
      font-family: ${(props) =>
        props.theme?.theme === THEME.VODAFONE ? "Vodafone" : "Muli"};
      background: ${(props) =>
        props.theme?.theme === THEME.VODAFONE ? color.vodaRed : color.greenish};
      font-size: ${(props) =>
        props.theme?.theme === THEME.VODAFONE ? "16px" : "14px"};
    }

    h1 {
      color: ${(props) =>
        props.theme?.theme === THEME.VODAFONE
          ? color.nero
          : color.greenish};
    }

    input {
      font-family: ${(props) =>
        props.theme?.theme === THEME.VODAFONE ? "Vodafone" : "Muli"};
      font-size: ${(props) =>
        props.theme?.theme === THEME.VODAFONE ? "18px" : "16px"};
    }
  }
`;
