import {
  gql
} from "apollo-boost";

const GET_CUSTOMER = gql `
  query customer($claim: String!) {
    customer(claim: $claim) {
      id
      fullName
      nationalID
      email
      phonenumber
      token
      terms {
        type
        accepted
      }
    }
  }
`;

export {
  GET_CUSTOMER
};