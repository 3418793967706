import jwtDecode from "jwt-decode";

const checkValidToken = () => {
  const token = localStorage.getItem("id_token");
  if (!token) {
    return null;
  }

  const decodedToken = jwtDecode(token);

  if (decodedToken.exp < Date.now() / 1000) {
    localStorage.removeItem("id_token");
    return null;
  }
  return token;
};

export { checkValidToken };
