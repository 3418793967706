const getProcess = (state) => state.process;
const getProcessId = (state) => state.process.orderId;
const getProcessStatus = (state) => state.process.status;
const getProcessPollCount = (state) => state.process.pollCount;

const processError = (state) => state.process.error;

export {
  getProcess,
  getProcessId,
  getProcessStatus,
  processError,
  getProcessPollCount,
};
