import styled from "styled-components";
import { color } from "../Color/colors";

const Input = styled.input`
  display: flex;
  font-weight: 200;
  height: 40px;
  line-height: 24px;
  padding-left: 15px;
  border: 1px solid ${color.inputborder};
  border-radius: 4px;
  max-width: ${(props) => `${props.maxWidth}%` ?? "60%"};
  flex-grow: ${(props) => props.flexGrow ?? "2"};
  margin-right: ${(props) => props.marginRight ?? "10px"};
  width: ${(props) => props.width ?? "initial"};

  background-color: ${color.antiFlashWhite};

  -webkit-box-sizing: ${(props) =>
    props.boxSizing ?? "initial"}; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: ${(props) =>
    props.boxSizing ?? "initial"}; /* Firefox, other Gecko */
  box-sizing: ${(props) => props.boxSizing ?? "initial"};

  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  :focus {
    outline: 0;
  }
`;

export { Input };
