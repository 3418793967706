const getProduct = (state) => state.product;
const getProductId = (state) => state.product.id;
const getProductName = (state) => state.product.name;
const getProductPrice = (state) => state.product.price.amounts;
const getProductInfo = (state) => state.product.productInfo;
const getProductTitle = (state) => state.product.productInfo.title;
const getProductSubheader = (state) => state.product.productInfo.subheader;
const getProductShortDescription = (state) =>
  state.product.productInfo.shortDescription;
const getProductLongDescription = (state) =>
  state.product.productInfo.longDescription;
const getProductImageUrl = (state) => state.product.productInfo.imageUrl;
const getProductIconUrl = (state) => state.product.productInfo.iconUrl;
const getProductChannelsUrl = (state) => state.product.productInfo.iconsUrl;
const getProductIsVoucher = (state) => state.product.isVoucher;
const isProductPPV = (state) => {
  return state.product?.parameters?.length > 0
    ? !!state.product.parameters.find((p) => p.name === "ppv")
    : false;
};
const isGiftCardAccepted = (state) => {
  return state.product?.parameters?.length > 0
  ? !!state.product.parameters.find((p) => p.name === "giftCard")
  : false;
}
const getSubscriptions = (state) => state.product.subscriptions;

const getProductList = (state) => state.product.productList;

export {
  getProduct,
  getProductId,
  getProductName,
  getProductPrice,
  getProductInfo,
  getProductTitle,
  getProductSubheader,
  getProductShortDescription,
  getProductLongDescription,
  getProductImageUrl,
  getProductIconUrl,
  getProductChannelsUrl,
  getProductIsVoucher,
  isProductPPV,
  isGiftCardAccepted,
  getSubscriptions,
  getProductList
};
