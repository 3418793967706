import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProduct,
  isProductPPV,
} from "../../../redux/reducers/product/selectors";
import { setHasDiscount } from "../../../redux/reducers/user/actions";
import { getVoucherCode } from "../../../redux/reducers/voucher/selectors";
import { NormalText, Spinner, BoldText } from "../../Shared/Shared";
import { formatPrice, getDiscountPercentage } from "../../Shared/utility";
import {
  SummaryItem as Item,
  SummaryLabel,
  VoucherPriceAnimation,
  VoucherPrice,
  OriginalPrice,
} from "../Styles";
import { GET_PRODUCTS_RATE_MONTHLY } from "./queries";

export default function MonthlyPrice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector(getProduct);
  const ppv = useSelector(isProductPPV);
  const voucherCode = useSelector(getVoucherCode);
  const [voucherPrice, setVoucherPrice] = useState(null);

  const { data, loading } = useQuery(GET_PRODUCTS_RATE_MONTHLY, {
    variables: {
      productId: Number(product.id),
    },
    onError: () =>
      navigate("/error", {
        state: {
          errorMessage: "Mistókst að sækja verð",
        },
      }),
  });

  useEffect(() => {
    if (data?.productsRateMonthly) {
      if (
        data.productsRateMonthly.listPrice >
        data.productsRateMonthly.actualPrice
      ) {
        dispatch(setHasDiscount(true));
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (voucherCode) {
      setVoucherPrice(product.price.amount);
    }
  }, [voucherCode, product]);

  if (loading) {
    return <Spinner />;
  }

  if (voucherCode && voucherPrice >= 0) {
    return (
      <VoucherPriceAnimation>
        <Item>
          <SummaryLabel>Þitt verð</SummaryLabel>
          <VoucherPrice>
            <OriginalPrice>{product.listPrice.amount}&nbsp;kr.</OriginalPrice>
            <NormalText>
              {voucherPrice}&nbsp;kr.&nbsp;(
              <BoldText>
                {getDiscountPercentage(voucherPrice, product.listPrice.amount)}
              </BoldText>
              %&nbsp;afsláttur)
            </NormalText>
          </VoucherPrice>
        </Item>
      </VoucherPriceAnimation>
    );
  }

  // Almennt verð vs Þitt verð
  if (data?.productsRateMonthly) {
    return (
      <>
        <Item>
          {data.productsRateMonthly.listPrice >
          data.productsRateMonthly.actualPrice ? (
            <SummaryLabel>Almennt verð</SummaryLabel>
          ) : (
            <SummaryLabel>Verð</SummaryLabel>
          )}
          <NormalText>
            {data.productsRateMonthly.listPrice && (
              <>
                <BoldText>
                  {formatPrice(data.productsRateMonthly.listPrice)}
                </BoldText>
                <>&nbsp;kr.{!product.singleMonth && !ppv ? " á mánuði" : ""}</>
              </>
            )}
          </NormalText>
        </Item>
        {data.productsRateMonthly.listPrice >
          data.productsRateMonthly.actualPrice && (
          <Item>
            <SummaryLabel>Þitt verð</SummaryLabel>
            <NormalText>
              {data.productsRateMonthly.listPrice && (
                <>
                  <BoldText>
                    {formatPrice(data.productsRateMonthly.actualPrice)}
                  </BoldText>
                  <>
                    &nbsp;kr.{!product.singleMonth && !ppv ? " á mánuði" : ""}
                  </>
                </>
              )}
            </NormalText>
          </Item>
        )}
      </>
    );
  } else {
    return null;
  }
}
