import styled from "styled-components";
import { Color } from "../Shared";
import { color } from "../Color/colors";

const NormalText = styled.div`
  font-size: 17px;
  color: ${Color.nero};
  font-weight: 300;
  line-height: 20px;
`;

const BoldText = styled.span`
  font-size: 17px;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${color.teal};
`;

const LargeText = styled.div`
  font-size: 48px;
`;

const LabelText = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
  letter-spacing: 0.2px;
  line-height: 16px;
  height: 16px;
  margin: ${(props) => (props.margin ? props.margin : "initial")};
  color: ${(props) => (props.disabled ? color.inputborder : color.vulcan)};
`;

const ErrorText = styled.div`
  color: ${color.errorRed};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.27px;
  line-height: 18px;
`;

const StrikethroughText = styled(NormalText)`
  text-decoration: line-through;
`;

export {
  NormalText,
  LabelText,
  LargeText,
  ErrorText,
  StrikethroughText,
  BoldText,
};
