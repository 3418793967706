import React, { useEffect, useState } from 'react';
import { useNavigate } from "@reach/router";
import { useLazyQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { setProduct, setProductPrice } from "../../redux/reducers/product/actions";
import { setVoucher } from "../../redux/reducers/voucher/actions";
import { isVoucherActive } from '../../redux/reducers/voucher/selectors';
import { SELLABLE_BY_PRODUCT_ID } from "../Product/queries";
import { GET_PRODUCTS_RATE_VOUCHER } from './Voucher/queries';
import { getFirstRatePeriod, getGraphQLErrorCodes } from '../Shared/utility';
import { getProduct, isGiftCardAccepted, isProductPPV } from '../../redux/reducers/product/selectors';

const useRestoreProduct = (token) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector(getProduct);
  const productId = localStorage.getItem('product_id');

  const [sellableProduct, { loading, error }] = useLazyQuery(
    SELLABLE_BY_PRODUCT_ID,
    {
      variables: {
        productId: Number(productId),
      },
      onCompleted: (data) => {
        if (data.sellableByProductId) {
          dispatch(setProduct(data.sellableByProductId));
        } else {
          navigate("/error", { 
            state: { 
              errorMessage: "Vara fannst ekki" 
            }
          });
        }
      }
    }
  );

  useEffect(() => {
    if (productId && token && !product?.parameters) {
      sellableProduct();
    }
  }, [productId, token, product, sellableProduct]);


  if (error) {
    navigate("/error", { 
      state: { 
        errorMessage: "Mistókst að sækja upplýsingar um vöru" 
      }
    });
  }
};

const useRestoreVoucher = (savedVoucherCode) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const voucherActive = useSelector(isVoucherActive);
  const ppv = useSelector(isProductPPV);
  const giftCard = useSelector(isGiftCardAccepted);
  const product = useSelector(getProduct);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const [activateVoucher, { loading: voucherLoading }] = useLazyQuery(
    GET_PRODUCTS_RATE_VOUCHER,
    {
      onCompleted: (data) => {
        if (data?.productsRate) {
          const firstPeriod = getFirstRatePeriod(data);
          if (firstPeriod) {
            const { actualPrice, listPrice } = firstPeriod;
            dispatch(setProductPrice(actualPrice, listPrice));
            dispatch(setVoucher(savedVoucherCode));
            localStorage.removeItem("voucher_code");
            setErrorMessage(null);
          } else {
            setErrorMessage("Mistókst að skrá kóða. Vinsamlegast reyndu aftur síðar.");
          }
        }
      },
      onError: (error) => errorHandler(error),
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (product?.id && savedVoucherCode && !voucherActive) {
      if (ppv || giftCard) {
        activateVoucher({
          variables: {
            productId: Number(product.id),
            voucherCode: savedVoucherCode.trim(),
          },
        });
      }
    }
  }, [product, savedVoucherCode, voucherActive, ppv, giftCard, activateVoucher])

  function errorHandler(error) {
    let errorCodes = getGraphQLErrorCodes(error);
    setErrorMessage(
      errorCodes.includes("BAD_USER_INPUT")
        ? "Kóði fannst ekki fyrir þessa vöru."
        : errorCodes.includes("FORBIDDEN") ? "Of margar tilraunir, reyndu aftur síðar"
        :"Mistókst að skrá kóða. Vinsamlegast reyndu aftur síðar."
    );
  }

  if (errorMessage) {
    localStorage.removeItem("voucher_code");
    navigate("/error", { 
      state: { 
        errorMessage: errorMessage 
      }
    })
  }
}

export { useRestoreProduct, useRestoreVoucher };