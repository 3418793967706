import React from "react";
import styled from "styled-components";
import { color } from "../Shared/Color/colors";
import { THEME } from "../../utility/constants";

export default function ProcessLoader({ label, theme }) {
  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 150px;
  `;

  const Cutout = styled.div`
    display: flex;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: ${color.whitesmoke};
    justify-content: center;
    align-items: center;
    position: absolute;
  `;

  const ProcessText = styled.div`
    width: 100%;
    color: ${(props) =>
      props.theme === THEME.VODAFONE ? color.nero : color.greenish};
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    text-align: center;
  `;

  const Loader = styled.div`
    width: 200px;
    height: 200px;
    background: ${(props) =>
      props.theme === THEME.VODAFONE
        ? "linear-gradient(#E60000 0%, #820000 100%)"
        : "linear-gradient(#205257 0%, #000 100%)"};
    border-radius: 50%;
    animation: spin 750ms infinite;
    position: absolute;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `;

  return (
    <Container>
      <Loader theme={theme} />
      <Cutout>
        <ProcessText theme={theme}>{label}</ProcessText>
      </Cutout>
    </Container>
  );
}
