/* eslint react-hooks/exhaustive-deps: 0 */
import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../redux/reducers/user/actions";
import { Spinner } from "./Shared/Shared";
import { Container } from "./Static/Styles";
import { getTheme } from "../redux/reducers/theme/selectors";
import { THEME, VODAFONE_REDIRECT, STOD2_REDIRECT } from "../utility/constants";

export default function Root() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  useEffect(() => {
    dispatch(clearUser());
    navigate(theme === THEME.VODAFONE ? VODAFONE_REDIRECT : STOD2_REDIRECT);
  }, []);

  return (
    <Container>
      <Spinner />
    </Container>
  );
}
