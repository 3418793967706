/* eslint react-hooks/exhaustive-deps: 0 */
import { useNavigate } from "@reach/router";
import "moment/locale/is";
import React, { useEffect } from "react";
import * as ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import myndlykill from "../../images/link-myndlykill.jpg";
import stod2app from "../../images/link-stod2-app.jpg";
import vefsjonvarp from "../../images/link-vefsjonvarp.jpg";
import { getActiveDonation } from "../../redux/reducers/donation/selectors";
import {
  getProduct,
  isProductPPV,
} from "../../redux/reducers/product/selectors";
import { getUser } from "../../redux/reducers/user/selectors";
import { MINAR_SIDUR_URL } from "../../utility/constants";
import AlertWrap from "../Shared/Alert/AlertWrap";
import { Break, CenteredContainer } from "../Shared/Layout/Layout";
import LinkButton from "../Shared/Link/Link";
import LinkBox from "./LinkBox";
import { LinkBoxGrid, ResultContainer } from "./Styles";

export default function Result({ type = "order" }) {
  const navigate = useNavigate();
  const product = useSelector(getProduct);
  const user = useSelector(getUser);
  const donation = useSelector(getActiveDonation);
  const ppv = useSelector(isProductPPV);

  useEffect(() => {
    window.analytics.page("Result");
    validation();
  }, []);

  function validation() {
    if (type === "order") {
      if (!product?.id) {
        navigate("/notfound");
        return <></>;
      }
      if (!user.authenticated) {
        navigate("/unauthorized");
        return <></>;
      }
    }
  }

  function getSuccessText() {
    if (type === "order") {
      if (ppv) {
        return getPPVText();
      }
      if (user.providerType) {
        if (
          user.providerType === "vodafone" &&
          (!user.tvEquipmentCount || user.tvEquipmentCount < 1)
        ) {
          return (
            getOnlyOttSuccessText() +
            getVoucherText() +
            getDonationThankYouText()
          );
        }
        return (
          getEquipmentSuccessText() +
          getVoucherText() +
          getDonationThankYouText()
        );
      } else {
        return (
          getOnlyOttSuccessText() + getVoucherText() + getDonationThankYouText()
        );
      }
    }
    return "Takk fyrir að hafa samband við okkur. Söluráðgjafi hefur samband um leið og færi gefst.";
  }

  function getOnlyOttSuccessText() {
    return user.ott
      ? "Sjónvarpsefnið verður aðgengilegt eftir nokkrar mínútur í vefsjónvarpi Stöðvar 2 og í Stöð 2 appinu."
      : "Sjónvarpsefnið verður aðgengilegt eftir nokkrar mínútur í vefsjónvarpi Stöðvar 2 og í Stöð 2 appinu. Stofnaðu aðgang hér að neðan til að byrja að horfa.";
  }

  function getEquipmentSuccessText() {
    let providerName = user.providerType === "siminn" ? "Símanum" : "Vodafone";
    let equipmentTerm = user.tvEquipmentCount > 1 ? "myndlyklum" : "myndlykli";
    let appText =
      user.ott && user.providerType !== "siminn" ? " og í Stöð 2 appinu." : ".";
    return `Sjónvarpsefnið verður aðgengilegt eftir nokkrar mínútur á ${equipmentTerm} þínum hjá ${providerName}${appText}`;
  }

  function getVoucherText() {
    return !!product.voucher
      ? ` Kóðinn til að virkja ${getFriendlyVoucherName()} var sendur með sms-i í símanúmerið þitt ásamt nánari leiðbeiningum.`
      : "";
  }

  function getFriendlyVoucherName() {
    return product.voucher === "NFL_VOUCHER"
      ? "NFL Game Pass"
      : product.voucher === "NBA_VOUCHER"
      ? "NBA League Pass"
      : product.voucher;
  }

  function getDonationThankYouText() {
    return donation
      ? ` ${donation.thankYouText
          .replace("{name}", donation.label)
          .replace("{amount}", donation.amount)}`
      : "";
  }

  function getPPVText() {
    return ppv ? (
      <ReactMarkdown
        source={product.productInfo.resultText}
        escapeHtml={false}
      />
    ) : (
      ""
    );
  }

  function getWebTvText() {
    return user.providerType === "siminn" ? (
      <>
        <div>
          Viðskiptavinir með myndlykil frá Vodafone geta notað áskriftina sína
          samtímis í myndlykli og vefsjónvarpi Stöðvar 2.
        </div>
        <Break size={"5px"} />
        <div>
          Horfðu hvar og hvenær sem er með vefsjónvarpi Stöðvar 2. Í
          vefsjónvarpinu er hægt að njóta alls þess efnis sem Stöð 2 hefur upp á
          að bjóða.
        </div>
      </>
    ) : (
      <div>
        Horfðu hvar og hvenær sem er með vefsjónvarpi Stöðvar 2. Í
        vefsjónvarpinu er hægt að njóta alls þess efnis sem Stöð 2 hefur upp á að
        bjóða."
      </div>
    );
  }

  function getAppText() {
    return user.providerType === "siminn" ? (
      <>
        <div>
          Viðskiptavinir með myndlykil frá Vodafone geta notað áskriftina sína
          samtímis í myndlykli og Stöð 2 appinu.
        </div>
        <Break size={"5px"} />
        <div>
          Horfðu hvar og hvenær sem er á þitt sjónvarpsefni með Stöð 2 appinu.
          Appið er aðgengilegt fyrir Android, iOS og einnig fyrir AppleTV 4
          snjalltæki.
        </div>
      </>
    ) : (
      <div>
        Horfðu hvar og hvenær sem er á þitt sjónvarpsefni með Stöð 2 appinu.
        Appið er aðgengilegt fyrir Android, iOS og einnig fyrir AppleTV 4
        snjalltæki.
      </div>
    );
  }

  return (
    <CenteredContainer>
      <ResultContainer>
        <AlertWrap
          level="success"
          animated
          header={
            type === "order" ? "Takk fyrir kaupin" : "Pöntun á símtali mótekin"
          }
          body={
            <div>
              {getSuccessText()}{" "}
              {user.tvEquipmentCount > 1 ||
                (user.providerType === "siminn" && (
                  <div>
                    Á{" "}
                    <LinkButton
                      to={MINAR_SIDUR_URL}
                      label="mínum síðum"
                      fontSize={"22px"}
                    />{" "}
                    er hægt að færa áskrift yfir á aðra myndlykla.
                  </div>
                ))}
            </div>
          }
        />

        <LinkBoxGrid>
          {!ppv && (
            <LinkBox
              imageUrl={vefsjonvarp}
              url={
                user.ott
                  ? "https://sjonvarp.stod2.is/login"
                  : `https://sjonvarp.stod2.is/register?username=${user.email}&phone=${user.phonenumber}&ssn=${user.nationalID}`
              }
              header="Vefsjónvarp"
              body={getWebTvText()}
              buttonLabel={user.ott ? "OPNA VEFSJÓNVARP" : "STOFNA AÐGANG"}
              showButton={type === "order" || user.providerType === "siminn"}
            />
          )}
          {!ppv && (
            <LinkBox
              imageUrl={stod2app}
              url="https://vodafone.is/sjonvarp/sjonvarpsoppin/stod-2-appid/"
              header="Stöð 2 appið"
              body={getAppText()}
              buttonLabel={user.ott ? "SÆKJA STÖÐ 2 APPIÐ" : "SÆKJA STÖÐ 2 APPIÐ"}
              showButton={type === "order" || user.providerType === "siminn"}
            />
          )}
          <LinkBox
            imageUrl={myndlykill}
            url="https://vodafone.is/sjonvarp/sjonvarpsthjonusta/myndlyklar/"
            header="Vodafone myndlykill"
            body="Háskerpumyndlykill fyrir gagnvirkt sjónvarp veitir aðgang að leigunni, tímavél, Frelsi, yfir 100 sjónvarpsstöðum og fleiru."
            buttonLabel="PANTA MYNDLYKIL"
            showButton={false}
          />
        </LinkBoxGrid>
      </ResultContainer>
    </CenteredContainer>
  );
}
