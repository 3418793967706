import { useNavigate } from "@reach/router";
import React from "react";
import { Button, NormalText, SubHeader } from "../Shared/Shared";
import { PhoneCall as Container } from "./Styles";
import { Break } from "../Shared/Layout/Layout";
import { useSelector } from "react-redux";
import { isProductPPV } from "../../redux/reducers/product/selectors";

export default function PhonecallInfo() {
  const navigate = useNavigate();
  const ppv = useSelector(isProductPPV);

  return (
    <Container>
      <SubHeader>Ekki með rafræn skilríki?</SubHeader>
      <Break />
      <NormalText>
        Pantaðu símtal hjá söluráðgjafa til að virkja{" "}
        {ppv ? "viðburð" : "sjónvarpsáskrift"} fyrir þig.
      </NormalText>
      <Break size={"30px"} />
      <Button active responsive onClick={() => navigate("/phonecall")}>
        PANTA SÍMTAL
      </Button>
    </Container>
  );
}
