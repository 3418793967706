import { gql } from "apollo-boost";

export const GET_DISTRIBUTION = gql`
  query rankedDistributions($productId: Int){
    rankedDistributions(selectTopRank: true, includeFamily: true, productId: $productId) {
      providerCustomerId
      providerType
      equipmentCount
      subscriptionCount
      tvSystemProperties {
        hasOttUser
      }
    }
  }
`;
