import { fadeIn } from "react-animations";
import styled, { css, keyframes } from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";
import { alert, color } from "../Color/colors";

const fadeInAnimation = keyframes`${fadeIn}`;

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid
    ${(props) => (props.level ? alert[props.level] : color.lightGrey)};
  animation: ${(props) =>
    props.animated
      ? () =>
          css`
            ${fadeInAnimation} 1500ms
          `
      : null};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  padding: 20px 20px 20px 0;
`;

export const AlertTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 25px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-left: 10px;
  }
`;

export const AlertText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.27px;
  text-align: left;
`;

export const AlertHeader = styled(AlertText)`
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-left: 10px;
    img {
      width: 21px;
      height: 21px;
    }
  }

  img {
    width: 36px;
    height: 36px;
  }
`;

export const Close = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: fit-content;

  img {
    width: 25px;
    height: 25px;

    :hover {
      cursor: pointer;
    }
  }
`;
