import {
  SET_DONATIONS,
  ACTIVATE_DONATION,
  SET_ACTIVE_DONATION_TYPE,
  CLEAR_ACTIVE_DONATION,
  SET_IS_DONATING,
  SET_DONATION_PRODUCT,
  CLEAR_DONATION_PRODUCT
} from "./actions";

const initialState = {
  donations: [],
  activeDonation: null,
  isDonating: false,
  donationProduct: null,
};

export default function donationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DONATIONS:
      return {
        ...state,
        donations: action.donations,
      };
    case ACTIVATE_DONATION:
      return {
        ...state,
        activeDonation: action.donation,
      };
    case SET_ACTIVE_DONATION_TYPE:
      return {
        ...state,
        activeDonation: { ...state.activeDonation, type: action.donationType },
      };
    case CLEAR_ACTIVE_DONATION:
      return {
        ...state,
        activeDonation: null,
      };
    case SET_IS_DONATING:
      return {
        ...state,
        isDonating: action.donating,
      };
    case SET_DONATION_PRODUCT:
      return {
        ...state,
        donationProduct: action.product,
      };
    case CLEAR_DONATION_PRODUCT:
      return {
        ...state,
        donationProduct: null,
      };
    default:
      return state;
  }
}
