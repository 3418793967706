import { gql } from "apollo-boost";

export const GET_PRODUCTS_RATE_VOUCHER = gql`
  query productsRateVoucher($productId: Int!, $voucherCode: String) {
    productsRate(
      input: {
        before: { products: [] }
        after: {
          products: [{ productId: $productId }]
          voucherCode: $voucherCode
        }
      }
    ) {
      periods {
        actualPrice
        firstPeriod
        listPrice
      }
    }
  }
`;
