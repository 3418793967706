import { pulse } from "react-animations";
import styled, { keyframes } from "styled-components";
import {
  LAYOUT_MAX_WIDTH,
  MOBILE_BREAKPOINT,
  THEME,
} from "../../utility/constants";
import { color } from "../Shared/Color/colors";
import { StrikethroughText } from "../Shared/Typography/Text";

const pulseAnimation = keyframes`${pulse}`;

const TopLevelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SummaryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: ${LAYOUT_MAX_WIDTH};
  padding: 50px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    max-width: none;
    padding: 20px;
  }
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr);` : `repeat(4, 1fr);`};
  grid-auto-rows: auto;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
  }
`;

const GridContainer = styled.div`
  margin-top: 30px;
  padding: ${(props) => (props.theme === THEME.VODAFONE ? "20px" : "0px")};
  background: ${(props) =>
    props.theme === THEME.VODAFONE ? color.white : `transparent`};
  box-shadow: ${(props) =>
    props.theme === THEME.VODAFONE
      ? `0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)`
      : null};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: ${(props) => (props.theme === THEME.VODAFONE ? "10px" : "0px")};
  }
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryLabel = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: ${color.black};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 5px;
  }
`;

const Price = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const PriceLabel = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 45px;
  color: ${color.black};
`;

const PriceItem = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;

  color: ${color.black};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 10px;
  }
`;

const ConfirmButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "flex-start")};
`;

const VoucherPrice = styled.div`
  display: flex;
  flex-direction: row;
`;

const OriginalPrice = styled(StrikethroughText)`
  color: ${color.darkGrey};
  margin-right: 5px;
`;

const VoucherPriceAnimation = styled.div`
  animation: ${pulseAnimation} 500ms;
`;

export {
  SummaryContainer,
  SummaryGrid,
  GridContainer,
  Price,
  PriceItem,
  PriceLabel,
  SummaryItem,
  SummaryLabel,
  ConfirmButtonLabel,
  ConfirmButtonContainer,
  TopLevelContainer,
  VoucherPriceAnimation,
  VoucherPrice,
  OriginalPrice,
};
