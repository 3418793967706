/* eslint-disable react/prop-types */
import React, { useState } from "react";
import * as moment from "moment";
import {
  UpSellCard,
  UpSellImage,
  UpSellInfo,
  UpSellText,
  SmallTitle,
  UpSellLabel,
  DescriptionText,
  UpSellFooter,
  UpSellPrice,
  UpSellButton,
  UpSellOverlay,
  Icon,
} from "../Summary.styles";
import "moment/locale/is";

export default function UpSellItem(props) {
  const { product, remove, add } = props;

  const [inKart, setInKart] = useState(false);

  return (
    <UpSellCard>
      <UpSellImage>
        <UpSellLabel>
          {moment().format("Do MMMM")} -{" "}
          {moment(product?.endDateFriendly).format("Do MMMM")}
        </UpSellLabel>
        <img src={product?.productInfo?.imageUrl} alt="productImage" />
      </UpSellImage>
      <UpSellInfo>
        <UpSellText>
          <SmallTitle>
            {product?.productInfo?.title ?? "Enginn titill"}
          </SmallTitle>
          <DescriptionText>
            {product?.productInfo?.shortDescription ?? "Enginn lýsing"}
          </DescriptionText>
        </UpSellText>
      </UpSellInfo>
      <UpSellFooter>
        <UpSellPrice>
          {product?.price?.amount
            ? `+ ${product?.price?.amount} kr.`
            : "Ekkert verð"}
        </UpSellPrice>
        <UpSellButton
          added={inKart}
          onClick={(e) => {
            e.preventDefault();
            if (inKart) {
              setInKart(false);
              remove(product);
            } else {
              setInKart(true);
              add(product);
            }
          }}
        >
          {inKart ? (
            <Icon>
              <img src="/images/check.svg" alt="help" />
            </Icon>
          ) : (
            <>
              <Icon>
                <img src="/images/add.svg" alt="help" />
              </Icon>
              Bæta við
            </>
          )}
        </UpSellButton>
      </UpSellFooter>
      <UpSellOverlay show={inKart} />
    </UpSellCard>
  );
}
