/* eslint-disable react/prop-types */
import React from "react";
import {
  ProductCard,
  ProductImage,
  ProductInfo,
  ProductInfoText,
  SmallTitle,
  DescriptionText,
  WarningIcon,
  WarningContainer,
  WarningText,
  WarningLabel,
  ProductCardContainer,
} from "../Summary.styles";
import { formatPrice } from "../../Shared/utility";
import { MINAR_SIDUR_URL } from "../../../utility/constants";
import { NormalText, BoldText } from "../../Shared/Shared";
import LinkButton from "../../Shared/Link/Link";

export default function ProductItem(props) {
  const { product, conflict } = props;

  return (
    <ProductCardContainer>
      <ProductCard hasConflict={conflict}>
        <ProductImage>
          <img src={product?.productInfo?.imageUrl} alt="productImage" />
        </ProductImage>
        <ProductInfo>
          <ProductInfoText>
            <SmallTitle>
              {product?.name ?? product?.productInfo?.title ?? "Enginn titill"}
            </SmallTitle>
            <DescriptionText>
              {product?.productInfo?.shortDescription ?? "Enginn lýsing"}
            </DescriptionText>
          </ProductInfoText>
          <NormalText>
            {product?.price?.amount ? (
              <>
                <BoldText>{formatPrice(product?.price?.amount)}</BoldText>
                <>
                  &nbsp;kr.{" á mánuði"}
                  {conflict}
                </>
              </>
            ) : (
              "Ekkert verð"
            )}
          </NormalText>
        </ProductInfo>
        <WarningIcon>
          <img src="/images/warning.svg" alt="warning" />
        </WarningIcon>
      </ProductCard>
      {conflict && (
        <WarningContainer>
          <WarningText>
            <WarningLabel>
              <img src="/images/warning.svg" alt="warning" />
            </WarningLabel>
            Þú ert nú þegar áskrifandi af þessari þjónustu
          </WarningText>
          <LinkButton
            fontSize="12px"
            to={MINAR_SIDUR_URL}
            label="Mínar áskriftir"
          />
        </WarningContainer>
      )}
    </ProductCardContainer>
  );
}
