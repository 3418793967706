import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { isEmail } from "validator";
import { setGDPRTerms, setUserEmail } from "../../redux/reducers/user/actions";
import { getUser } from "../../redux/reducers/user/selectors";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import { Checkbox } from "../Shared/Checkbox/Checkbox";
import { Input } from "../Shared/Input/Input";
import InputError from "../Shared/Input/InputError";
import LinkButton from "../Shared/Link/Link";
import ProductImage from "../Shared/ProductImage/ProductImage";
import {
  Button,
  LabelText,
  Layout,
  NormalText,
  SubHeader,
} from "../Shared/Shared";
import { MODIFY_CUSTOMER } from "./mutations";
import { Form, UserHeader } from "./Styles";
import { getMenu } from "../../redux/reducers/menu/selectors";

const { Container, ActionContainer, Break, CenteredContainer } = Layout;

export default function User() {
  const user = useSelector(getUser);
  const menu = useSelector(getMenu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, errors, triggerValidation, watch } = useForm({
    mode: "onChange",
  });
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });

  useEffect(() => {
    async function init() {
      await triggerValidation();
    }
    init();
  }, [triggerValidation]);

  const [modifyCustomer, { loading }] = useMutation(MODIFY_CUSTOMER, {
    onCompleted: () =>
      menu.displayProposal
        ? navigate("/order/proposal")
        : navigate("/order/summary"),

    onError: () => navigate("/error"),
  });

  if (!user.authenticated) {
    navigate("/unauthorized");
    return <></>;
  }

  return (
    <CenteredContainer>
      <Container>
        <ActionContainer>
          {isTabletOrMobile && <ProductImage />}
          <UserHeader>Notendaupplýsingar</UserHeader>
          <SubHeader>Staðfesting og samþykki</SubHeader>
          <Break size={"20px"} />
          <NormalText>
            Til að geta veitt sjónvarpsþjónustu þurfum við eftirfarandi
            notendaupplýsingar,&nbsp;
            <LinkButton
              to={"https://syn.is/fyrirtaekid/verklag/personuverndarstefna"}
              label={"nánar hér."}
            />
          </NormalText>
          <Break size={"30px"} />
          <Form>
            <LabelText>Farsímanúmer*</LabelText>
            <Input
              disabled={true}
              name="phonenumber"
              type="text"
              defaultValue={user.phonenumber}
              maxLength={7}
            />
            <Break size={"10px"} />
            <LabelText>Netfang*</LabelText>
            <Input
              name="email"
              type="email"
              autoFocus={true}
              defaultValue={user.email}
              ref={register({
                required: true,
                validate: (value) => isEmail(value),
              })}
            />
            <InputError
              message={
                errors?.email?.type === "validate"
                  ? "Netfang ekki á réttu formi"
                  : ""
              }
            />
            <Break />
            <Checkbox
              label={
                <LinkButton
                  to="https://syn.is/fyrirtaekid/verklag/personuverndarstefna"
                  label="Ég vil fá upplýsingar um ný tilboð frá Stöð 2"
                />
              }
              callback={() => dispatch(setGDPRTerms(!user.gdprTerms))}
              initialValue={user.gdprTerms}
            />
            <Break size={"20px"} />
            <Button
              active={!errors.email && !loading}
              width="30"
              onClick={async (e) => {
                e.preventDefault();
                const valid = await triggerValidation();
                if (valid && !loading) {
                  const email = watch("email");
                  dispatch(setUserEmail(email));
                  modifyCustomer({
                    variables: {
                      modification: {
                        email: email,
                        terms: [
                          {
                            type: "GDPR",
                            version: "1.0",
                            accepted: user.gdprTerms,
                          },
                        ],
                      },
                    },
                  });
                }
              }}
            >
              ÁFRAM
            </Button>
          </Form>
        </ActionContainer>
        {!isTabletOrMobile && <ProductImage />}
      </Container>
    </CenteredContainer>
  );
}
