import { gql } from "apollo-boost";

export const GET_PRODUCTS_RATE = gql`
  query productsRate($input: RateData) {
    productsRate(input: $input) {
      periods {
        firstPeriod
        dateFrom
        dateTo
        actualPrice
      }
    }
  }
`;
export const GET_SUBSCRIPTIONS = gql`
  query contentSubscriptions {
    subscriptions(typeOf: ["content"], getTerminated: false, includeFamily: true, filterOutRetired: true) {
      product {
        id
      }
      mainResource
    }
  }
`;
export const GET_PRODUCT_PROPOSAL = gql`
  query productProposal(
    $productId: Int!
    $currentSubscriptions: [SubscriptionStub]
  ) {
    productProposal(
      productId: $productId
      currentSubscriptions: $currentSubscriptions
    ) {
      wantedChannels
      product {
        id
        name
        channels
        price {
          amount
        }
        productInfo {
          title
          name
          shortDescription
          longDescription
          imageUrl
          iconUrl
          iconsUrls
          subheader
        }
      }
      currentSelection {
        id
        name
        price {
          amount
        }
        productInfo {
          iconsUrls
        }
      }
      currentProducts {
        id
        name
        price {
          amount
        }
        productInfo {
          iconsUrls
        }
      }
      terminations {
        product {
          id
          name
          price {
            amount
          }
        }
        mainResource
      }
    }
  }
`;
