import rg4js from "raygun4js";
import { isDevelopment } from "./env";

export default function raygun() {
  const VERSION_NUMBER = "1.0.0.0";
  rg4js("apiKey", "2I1YV8tqRyenICvd9Ddi3A");
  rg4js("setVersion", VERSION_NUMBER);
  rg4js("enableCrashReporting", !isDevelopment());
  rg4js("enablePulse", true);
}
