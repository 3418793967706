import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProduct } from "../../redux/reducers/product/selectors";
import { SmallHeader } from "../Shared/Shared";
import Toggle from "../Shared/Toggle/Toggle";
import { Channels as ChannelsContainer } from "./Styles";

export default function Channels(props) {
  const product = useSelector(getProduct);
  const [iconsUrls, setIconsUrls] = useState([]);
  const { productInfo } = product;
  const productIconUrls = productInfo.iconsUrls;

  useEffect(() => {
    if (props.products) {
      let urls = [];
      for (const prod of props.products) {
        if (prod.productInfo) {
          urls = urls.concat(urls, prod.productInfo.iconsUrls);
        }
      }
      // filter out duplicates for multiple products
      setIconsUrls([...new Set(urls)]);
    } else if (productIconUrls) {
      setIconsUrls(productIconUrls);
    }
  }, [setIconsUrls, props.products, productIconUrls]);

  return (
    iconsUrls?.length > 0 && (
      <Toggle
        label={<SmallHeader>Listi yfir stöðvar</SmallHeader>}
        component={
          <ChannelsContainer animate>
            {iconsUrls?.length > 0 &&
              iconsUrls.map((iconUrl, i) => {
                return (
                  <img
                    key={i}
                    alt="Mynd af stöð"
                    src={`${iconUrl}?w=75&h=75`}
                  />
                );
              })}
          </ChannelsContainer>
        }
      />
    )
  );
}
