import React from "react";
import { BannerContainer, BannerImage, BannerInnerContainer } from "./Styles";
import stod2 from "../../../images/STod2.svg";
import vodafone from "../../../images/vf-logo.png";
import Hamburger from "../Menu/Hamburger";
import {
  STOD2_REDIRECT,
  VODAFONE_REDIRECT,
  THEME,
} from "../../../utility/constants";
import { useSelector } from "react-redux";
import { getTheme } from "../../../redux/reducers/theme/selectors";

export default function Banner() {
  const theme = useSelector(getTheme);
  const redirect =
    theme === THEME.VODAFONE ? VODAFONE_REDIRECT : STOD2_REDIRECT;
  return (
    <BannerContainer theme={theme}>
      <BannerInnerContainer>
        <BannerImage
          theme={theme}
          onClick={() => (window.location.href = redirect)}
          src={theme === THEME.VODAFONE ? vodafone : stod2}
          alt={theme}
        />
        <Hamburger theme={theme} />
      </BannerInnerContainer>
    </BannerContainer>
  );
}
