import styled, { css } from "styled-components";
import { color } from "../Color/colors";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";

const Button = styled.button`
  outline: 0;
  border: none;
  opacity: ${(props) => (props.active ? "1" : "0.7")};
  height: ${(props) => (props.height ? `${props.height}%` : "")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "initial")};
  color: ${(props) =>
    props.color ? props.color : props.active ? "white" : color.lightGrey};
  font-weight: 800;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "auto")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "auto")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "auto"};
  padding: ${(props) => (props.padding ? props.padding : "0.75em 0.75em")};
  border-radius: 5px;
  width: ${(props) => (props.width ? `${props.width}%` : "35%")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : "none")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "initial")};
  @media (max-width: 1350px) {
    width: ${(props) =>
      props.responsive
        ? props.responsiveWidth
          ? `${props.responsiveWidth}%`
          : "50%"
        : ""};
  }
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0);
  align-self: ${({ alignSelf }) => alignSelf || "auto"};
  transition: box-shadow 0.3s ease-out, background-color 0.3s ease-out;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: ${(props) => (props.width ? `${props.width}%` : "100%")};
  }

  :hover {
    outline: 0;
    ${(props) =>
      props.active
        ? css`
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
            cursor: pointer;
          `
        : css`
            cursor: not-allowed;
          `}
  }

  :active {
    outline: 0;
    ${(props) =>
      props.active &&
      css`
        color: ${color.white};
      `}
  }

  :focus {
    outline: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-end"};
  align-items: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  width: ${(props) => (props.width ? props.width : "100")}%;
`;

export { Button, ButtonGroup };
