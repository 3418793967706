import { MOBILE_BREAKPOINT, THEME } from "../../utility/constants";
import { color } from "../Shared/Color/colors";
import { Container as SharedContainer } from "../Shared/Layout/Layout";
import styled, { keyframes } from "styled-components";
import { zoomIn } from "react-animations";

const zoomInAnimation = keyframes`${zoomIn}`;

const Container = styled(SharedContainer)`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 75px;
  }
`;

const Image = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div``;

const Channels = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  justify-items: center;
  margin-bottom: 15px;

  img {
    width: 75px;
    height: 75px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ChannelToggle = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.theme === THEME.VODAFONE
      ? `1px solid ${color.nero}`
      : `1px solid ${color.greenish}`};
  border-radius: 4px;
  color: ${(props) =>
    props.theme === THEME.VODAFONE ? color.nero : color.greenish};
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  background: transparent;

  :hover {
    cursor: pointer;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Price = styled.div`
  font-size: 40px;
  color: ${(props) =>
    props.theme === THEME.VODAFONE ? color.vodaRed : color.greenish};
  font-weight: 600;
  margin-right: 5px;
`;

const SelectionContainer = styled.div`
  width: 100%;
  color: ${color.vulcan};
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-top: 25px;
  margin-bottom: 30px;
`;

const DonationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  animation: ${zoomInAnimation} 300ms;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const DonationSelectionContainer = styled.div`
  display: flex;
  width: 49%;
  margin-top: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    :first-child {
      margin-bottom: 20px;
    }
    :last-child {
      margin-top: 10px;
    }
  }

  :first-child {
    margin-right: 20px;
  }
`;

const ContinueMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
`;

const WhereToWatchContainer = styled.div`
  p {
    margin-block-start: 0.25em;
  }
`;

export {
  Container,
  Image,
  Description,
  Channels,
  Price,
  PriceContainer,
  SelectionContainer,
  ChannelToggle,
  ContinueMobile,
  WhereToWatchContainer,
  DonationContainer,
  DonationSelectionContainer,
};
