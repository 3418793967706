import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import {
  MOBILE_BREAKPOINT,
  LAYOUT_MAX_WIDTH,
} from "../../../utility/constants";
import { lightGrey } from "../Color/colors";

const fadeInAnimation = keyframes`${fadeIn}`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: ${LAYOUT_MAX_WIDTH};
  padding: 66px 50px 50px 50px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 20px;
    width: 100%;
  }
`;

const FadeInContainer = styled(Container)`
  animation: 1s ${fadeInAnimation};
`;

const ImageContainer = styled.div`
  display: flex;
  width: 60%;
  justify-content: flex-end;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }

  img {
    width: 85%;
    height: 100%;
  }
`;

const ImageContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    width: 100%;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
  }
`;

const Ruler = styled.hr`
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: ${lightGrey};
  height: 1px;
`;

const RulerSpacy = styled(Ruler)`
  margin-top: ${(props) => (props.space ? props.space : "40px")};
  margin-bottom: ${(props) => (props.space ? props.space : "40px")};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Break = styled.div`
  margin-bottom: ${(props) => (props.size ? props.size : "5px")};
`;

export {
  Container,
  FadeInContainer,
  ImageContainer,
  ActionContainer,
  Ruler,
  RulerSpacy,
  ImageContainerMobile,
  CenteredContainer,
  Break,
};
