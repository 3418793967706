export const color = {
  // Stod 2 color scheme
  white: "#FFF",
  greenish: "#205257",
  // Shades of black
  black: "#000",
  nero: "#181717",
  nightrider: "#333",
  vulcan: "#383B41",
  // Shades of grey
  mischka: "#A5ADBB",
  linkwater: "#A5ADBB",
  hawkesblue: "#DFE1E5",
  solitude: "#EBECF0",
  whitesmoke: "#F4F4F4",
  inputborder: "#C1C7D0",
  ruler: "#BDBDBD",
  // Vodafone color scheme
  vodaRed: "#E60000",
  darkRed: "#BD0100",
  lightGrey: "#E2E2E2",
  darkGrey: "#B3B3B3",
  darkerGrey: "#303030",
  backgroundGrey: "#F5F5F5",
  bgLightGrey: "#F9F9F9",
  errorRed: "#FC5652",

  // New Colors
  darkCharcoal: "#333333",
  davyGrey: "#5E5E5E",
  japaneseIndigo: "#1D484D",
  passionPurple: "#D4145A",
  teal: "#205257",
  tealPlus60: "#A6BABC",
  raisinBlack: "#212121",
  antiFlashWhite: "#F2F2F2",
  transparent: "transparent",
};

export const alert = {
  warning: "#FDBC40",
  success: "#2AC940",
  info: "#167CE1",
  error: "#FC5652",
};

export const vodaRed = "#E60000";
export const darkRed = "#BD0100";
export const lightGrey = "#E2E2E2";
export const darkGrey = "#B3B3B3";
export const darkerGrey = "#303030";
export const backgroundGrey = "#F5F5F5";
export const bgLightGrey = "#F9F9F9";
export const white = "#fff";
export const greenish = "#205257";
