import React from "react";
import { useDispatch, useSelector } from "react-redux";
import hamburger from "../../../images/button-menu.svg";
import vfHamburger from "../../../images/button-menu-black.svg";
import { toggleMenu } from "../../../redux/reducers/menu/actions";
import { isAuthenticated } from "../../../redux/reducers/user/selectors";
import { MenuIcon } from "./Styles";
import { THEME } from "../../../utility/constants";
import { getTheme } from "../../../redux/reducers/theme/selectors";

export default function Hamburger() {
  const dispatch = useDispatch();
  const user = useSelector(isAuthenticated);
  const theme = useSelector(getTheme);

  return user ? (
    <MenuIcon
      src={theme === THEME.VODAFONE ? vfHamburger : hamburger}
      onClick={() => dispatch(toggleMenu())}
    />
  ) : null;
}
