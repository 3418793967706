import rg4js from "raygun4js";
import React from "react";
import { useSelector } from "react-redux";
import notfound from "../../images/stod2_404_error.png";
import { getTheme } from "../../redux/reducers/theme/selectors";
import {
  STOD2_REDIRECT,
  THEME,
  VODAFONE_REDIRECT,
} from "../../utility/constants";
import { Break } from "../Shared/Layout/Layout";
import LinkButton from "../Shared/Link/Link";
import { NormalText } from "../Shared/Shared";
import { Container, ErrorDescription, ErrorHeader, ErrorImage } from "./Styles";

export default function NotFound() {
  const theme = useSelector(getTheme);
  rg4js("send", { error: "Landed on Not Found page" });
  window.analytics.page("Not found");
  return theme !== THEME.VODAFONE ? (
    <Container>
      <ErrorHeader>Obbosí!</ErrorHeader>
      <ErrorImage>
        <img src={notfound} alt="Villa kom upp" />
      </ErrorImage>
      <ErrorDescription>Það er ekkert að finna hér.</ErrorDescription>
      <LinkButton to={STOD2_REDIRECT} label="Fara á kaupsíðu stöð2.is" />
    </Container>
  ) : (
    <Container>
      <ErrorHeader>Úps... hvað gæti hafa gerst?</ErrorHeader>
      <NormalText style={{ maxWidth: "600px" }}>
        Síðan sem þú varst að leita að er því miður ekki á þessari slóð. Hún
        gæti hafa verið færð, henni eytt eða einhver mistök mögulega verið gerð.
        Ef þú lendir áfram í vandræðum getur þú haft samband við okkur í gegnum
        Netspjallið eða þjónustuver Vodafone í síma 1414.
      </NormalText>
      <Break />
      <LinkButton to={VODAFONE_REDIRECT} label="Fara á vodafone.is" />
    </Container>
  );
}
