import styled from "styled-components";
import {
  LAYOUT_MAX_WIDTH,
  MOBILE_BREAKPOINT,
  THEME,
} from "../../../utility/constants";
import { color } from "../Color/colors";

const { VODAFONE } = THEME;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.theme === VODAFONE ? "80px" : "100px")};
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.theme === VODAFONE ? color.white : color.black};
  color: ${(props) => (props.theme === VODAFONE ? color.black : color.white)};
  margin-bottom: ${(props) => (props.theme === VODAFONE ? "3px" : "0px")};
  box-shadow: ${(props) =>
    props.theme === VODAFONE ? "0 2px 3px 0 rgba(0,0,0,0.2)" : "none"};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    height: 64px;
  }
`;

export const BannerInnerContainer = styled.div`
  display: flex;
  max-width: ${LAYOUT_MAX_WIDTH};
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BannerImage = styled.img`
  height: ${(props) => (props.theme === VODAFONE ? "46px" : "42px")};
  width: ${(props) => (props.theme === VODAFONE ? "46px" : "74.52px")};

  @media (max-width: 1300px) {
    margin-left: 48px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    height: ${(props) => (props.theme === VODAFONE ? "46px" : "64px")};
    margin-left: 20px;
  }

  :hover {
    cursor: pointer;
  }
`;
