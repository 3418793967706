import rg4js from "raygun4js";

const raygunBreadcrumb = (store) => (next) => (action) => {
  const nextAction = next(action);
  const state = store.getState();

  rg4js("recordBreadcrumb", {
    message: `Executing Redux action '${action.type}'`,
    metadata: {
      action,
      state,
    },
    level: "info",
  });

  return nextAction;
};

export default raygunBreadcrumb;
