import { combineReducers } from "redux";
import productReducer from "./product/reducer";
import userReducer from "./user/reducer";
import processReducer from "./process/reducer";
import menuReducer from "./menu/reducer";
import themeReducer from "./theme/reducer";
import donationsReducer from "./donation/reducer";
import toggleReducer from "./toggle/reducer";
import voucherReducer from "./voucher/reducer"


const rootReducer = combineReducers({
  product: productReducer,
  user: userReducer,
  process: processReducer,
  menu: menuReducer,
  theme: themeReducer,
  donations: donationsReducer,
  toggle: toggleReducer,
  voucher: voucherReducer
});

export default rootReducer;
