import styled from "styled-components";
import { SubHeader, LargeHeader, SmallHeader } from "../Shared/Shared";
import { MOBILE_BREAKPOINT } from "../../utility/constants";
import { color } from "../Shared/Color/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
`;

const ErrorHeader = styled(LargeHeader)`
  @media (max-width: 530px) {
    font-size: 36px;
  }
`;

const ErrorMessage = styled(SubHeader)`
  max-width: 665px;
  border: 2px solid ${color.greenish};
  padding: 25px;
  margin-bottom: 50px;
  border-radius: 5px;
  color: ${color.greenish}
`;

const ErrorDescription = styled(SubHeader)`
  max-width: 665px;
  margin-bottom: 20px;
`;

const ErrorImage = styled.div`
  margin-bottom: 68px;
  img {
    width: 80%;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 34px;
    img {
    width: 100%;
  }
  }
`;

export { Container, ErrorDescription, ErrorImage, ErrorHeader, ErrorMessage };
