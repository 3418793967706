import styled from "styled-components";
import { color } from "../Color/colors";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";

const LinkStyle = styled.div`
  display: inline-flex;
  border: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  width: fit-content;
  text-decoration: ${(props) => (props.decoration ? props.decoration : "none")};
  background: transparent;
  color: ${color.teal};
  padding: 0;
  text-align: left;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  }
`;

export { LinkStyle };
