import styled from "styled-components";
import { color } from "../Color/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 19px;
  width: 100%;
  border: 1px solid ${color.inputborder};
  border-radius: 4px;
  background: ${color.white};
`;

export const TabHeader = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`;

export const Tab = styled.div`
  display: flex;
  text-align: center;
  max-height: 50px;
  width: 100%;
  color: ${color.nightrider};
  font-size: 12px;
  font-weight: 800;
  line-height: 32px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${color.inputborder};
  background: ${(props) => (props.active ? color.inputborder : "transparent")};
  padding: ${(props) => (props.padding ? props.padding : "0")};

  :last-child {
    border-right: none;
  }

  :hover {
    cursor: pointer;
  }

  @media (max-width: 660px) {
    line-height: 24px;
  }
`;
