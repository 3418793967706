const prefix = "voucher/";
const SET_VOUCHER = `${prefix}SET_VOUCHER`;

const setVoucher = (voucherCode, discount) => ({
  type: SET_VOUCHER,
  voucherCode,
  discount,
});

export { SET_VOUCHER, setVoucher };
