import React, { useState } from "react";
import styled from "styled-components";
import { color } from "../Color/colors";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? "#C1C7D0" : "white")};
  border-radius: 5px;
  border: ${(props) =>
    props.checked
      ? `1px solid ${color.darkGrey}`
      : `1px solid ${color.raisinBlack}`};
  transition: all 150ms;
  cursor: pointer;
  ${Icon} {
    transition: opacity 150ms ease-in-out;
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
`;

const CheckboxLabel = styled.span`
  margin-left: 8px;
  color: ${color.darkerGrey};
  cursor: pointer;
  width: 88%;
  display: inline-block;
  text-align: left;
`;

const CheckboxComponent = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline stroke={color.darkerGrey} points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export function Checkbox({ callback, label, initialValue = false }) {
  const [checked, setChecked] = useState(initialValue);
  return (
    <div>
      <label>
        <CheckboxComponent
          checked={checked}
          onChange={() => {
            let changedState = !checked;
            setChecked(changedState);
            callback(changedState);
          }}
        />
        <CheckboxLabel checked={checked}>{label}</CheckboxLabel>
      </label>
    </div>
  );
}
