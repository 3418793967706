import styled from "styled-components";
import { color } from "../../Shared/Color/colors";

export const PayIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

export const Dot = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 16px;
  width: 16px;
  height: 16px;

  border-radius: 50%;
  border: 1px solid
    ${(props) => (props.checked ? color.teal : color.tealPlus60)};
`;

export const Fill = styled.div`
  display: ${(props) => (props.checked ? "block" : "none")};
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background-color: ${(props) =>
    props.checked ? color.teal : color.tealPlus60};
`;

export const FormSlider = styled.div`
  overflow: hidden;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: 100%;
  max-height: ${(props) => (props.show ? 164 : 0)}px;
  padding-right: 2px;
  transition: all 0.5s ease;
`;
