import React from "react";
import { StyledCircle, StyledSpinner, Center } from "./Styles";
import { greenish, vodaRed } from "../Color/colors";
import { useSelector } from "react-redux";
import { getTheme } from "../../../redux/reducers/theme/selectors";
import { THEME } from "../../../utility/constants";

function Spinner({ color, size = 100 }) {
  const theme = useSelector(getTheme);
  if (!color) {
    color = theme === THEME.VODAFONE ? vodaRed : greenish;
  }

  return (
    <Center>
      <StyledSpinner viewBox="0 0 50 50" width={size} height={size}>
        <StyledCircle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="3"
          color={color}
        />
      </StyledSpinner>
    </Center>
  );
}

export default Spinner;
