import React from "react";
import { useSelector } from "react-redux";
import defaultImage1 from "../../../images/default1.png";
import { getProductImageUrl } from "../../../redux/reducers/product/selectors";
import { ImageContainer, ImageContainerMobile } from "../Layout/Layout";
import { useMediaQuery } from "react-responsive";
import { MOBILE_BREAKPOINT } from "../../../utility/constants";
import { Helmet } from "react-helmet";
import { Fragment } from "react";

export default function ProductImage() {
  const imageUrl = useSelector(getProductImageUrl);
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT})`,
  });
  const image = <img alt="product" src={imageUrl ?? defaultImage1} />;

  return (
    <Fragment>
      <Helmet defer={false} >
        <meta name="og:image" content={"https:" + imageUrl} />
        <meta property="twitter:image" content={"https:" + imageUrl} />
      </Helmet>
      {isTabletOrMobile ? 
        <ImageContainerMobile>{image}</ImageContainerMobile>
        :
        <ImageContainer>{image}</ImageContainer>
      }
    </Fragment>
  )
}
