import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPollCount,
  incrementPollCount,
} from "../../redux/reducers/process/actions";
import { getProcess } from "../../redux/reducers/process/selectors";
import { getProduct } from "../../redux/reducers/product/selectors";
import {
  PROCESS_ONGOING_STATUSES,
  PROCESS_STATUS,
} from "../../utility/constants";
import ProcessLoader from "./ProcessLoader";
import { GET_PROCESS_STATUS } from "./queries";
import { ProcessInfo } from "./Styles";
import { isAuthenticated } from "../../redux/reducers/user/selectors";
import { NormalText } from "../Shared/Shared";
import { getTheme } from "../../redux/reducers/theme/selectors";

export default function ProcessPoller() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const process = useSelector(getProcess);
  const authenticated = useSelector(isAuthenticated);
  const theme = useSelector(getTheme);
  const product = useSelector(getProduct);
  const [isDone, setIsDone] = useState(false);

  const NETWORK_STATUS_POLL = 6;
  const POLL_TIME = 1000; // 1s
  const MAX_POLL_COUNT = 60; // 1m

  const { data, error, startPolling, stopPolling, networkStatus } = useQuery(
    GET_PROCESS_STATUS,
    {
      variables: { orderId: Number(process.orderId) },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.process) {
          const { status } = data.process;
          if (PROCESS_ONGOING_STATUSES.includes(status)) {
          } else if (status === PROCESS_STATUS.DONE) {
            setIsDone(true);
            stopPolling();
          } else {
            stopPolling();
            navigate("/error");
          }
        }
      }
    }
  );

  useEffect(() => {
    startPolling(POLL_TIME);
  }, [startPolling]);

  useEffect(() => {
    if (networkStatus === NETWORK_STATUS_POLL) {
      dispatch(incrementPollCount());
    }
  }, [networkStatus, dispatch]);

  useEffect(() => {
    if (process.pollCount > MAX_POLL_COUNT) {
      stopPolling();
      navigate("/error", { 
        state: { 
          errorMessage: "Mistókst að sækja upplýsingar um pöntunarferli" 
        }
      });
    }
  }, [process.pollCount, stopPolling]);

  useEffect(() => {
    if (isDone) {
      sendOrderCompletedEvent();
      navigate("/order/result");
    }
  }, [isDone])

  const sendOrderCompletedEvent = () => {
    window.analytics.track("Order Completed", {
      order_id: process.orderId,
      affiliation: "kaup." + theme + ".is",
      total: product.price.amount,
      revenue: product.price.amount,
      currency: "ISK",
      products: [{
        product_id: product.id,
        sku: product.parameters.find(param => param.name === "productCode").defaultValue,
        category: "1988",
        brand: theme,
        name: product.name,
        price: product.price.amount,
        quantity: 1,
        url: window.location.host,
        image_url: product.productInfo.imageUrl
      }]
    });
  }

  if (!authenticated) {
    navigate("/unauthorized");
    return <></>;
  }

  if (!process || !process.orderId) {
    return <NormalText>Enginn ferill í gangi</NormalText>;
  }

  if (error) {
    stopPolling();
    dispatch(clearPollCount());
    navigate("/error", { 
      state: { 
        errorMessage: "Mistókst að sækja upplýsingar um pöntunarferli" 
      }
    });
  }

  return (
    <ProcessInfo>
      <ProcessLoader theme={theme} label="Kaup í vinnslu" />
    </ProcessInfo>
  );
}