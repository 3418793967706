const prefix = "menu/";
const TOGGLE_MENU = `${prefix}TOGGLE_MENU`;
const SHOW_MENU = `${prefix}SHOW_MENU`;
const HIDE_MENU = `${prefix}HIDE_MENU`;
const SET_DISPLAY_PROPOSAL = `${prefix}SET_DISPLAY_PROPOSAL`;
const SET_FAKE_OLD_PRODUCT_IDS = `${prefix}SET_FAKE_OLD_PRODUCT_IDS`;


const toggleMenu = () => ({
  type: TOGGLE_MENU
});

const showMenu = () => ({
  type: SHOW_MENU
});

const hideMenu = () => ({
  type: HIDE_MENU
});
// setDisplayProposal and setFakeOldProductId should be removed later 
// on when testing phase completes
const setDisplayProposal = (value) => ({
  type: SET_DISPLAY_PROPOSAL,
  value
});
const setFakeOldProductIds = (productIds) => ({
  type: SET_FAKE_OLD_PRODUCT_IDS,
  productIds
});

export {
  TOGGLE_MENU,
  SHOW_MENU,
  HIDE_MENU,
  SET_DISPLAY_PROPOSAL,
  SET_FAKE_OLD_PRODUCT_IDS,
  toggleMenu,
  showMenu,
  hideMenu,
  setDisplayProposal,
  setFakeOldProductIds
};