import { SET_THEME } from "./actions";

const initialState = {
  theme: "stod2",
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    default:
      return state;
  }
}
